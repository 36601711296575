import { Icon } from "@clevertrack/shared"
import { UserType } from "app/DataTable/components/UserType"
import { Search } from "app/Search"
import { SearchContext } from "app/Search/context"
import { freetextSearch } from "app/Search/helper"
import { IconSizeEnum } from "lib/Icon"
import React, { useContext, useMemo } from "react"
import "twin.macro"
import tw from "twin.macro"

export type DataListType = {
  items: any[]
  titleKey: string
  titleIcon?: string
  titleStyling?: any
  titleIconStyling?: any
  descriptionKey?: string | string[]
  descriptionKeySeparator?: string
  secondaryDescriptionKey?: string | string[]
  secondaryDescriptionKeySeparator?: string
  secondaryDescriptionKeyFormatFnc?: (x: any, k?: any) => void
  secondaryDescriptionStyling?: any
  tertiaryDescriptionKey?: string | string[]
  tertiaryDescriptionKeySeparator?: string
  tertiaryDescriptionKeyFormatFnc?: (x: any, k?: any) => void
  tertiaryDescriptionStyling?: any
  searchKeys?: string[]
  includeSearch?: boolean
  onItemSelect?: (item: any) => void
  searchPlaceHolder?: string
  selectedItem: any | null
}

export const DataList: React.FC<DataListType> = ({
  items,
  titleKey,
  titleIcon,
  titleStyling,
  titleIconStyling,
  descriptionKey,
  descriptionKeySeparator = " - ",
  secondaryDescriptionKey,
  secondaryDescriptionKeySeparator = " - ",
  secondaryDescriptionKeyFormatFnc,
  secondaryDescriptionStyling,
  tertiaryDescriptionKey,
  tertiaryDescriptionKeySeparator = " - ",
  tertiaryDescriptionKeyFormatFnc,
  tertiaryDescriptionStyling,
  searchKeys,
  includeSearch,
  onItemSelect,
  searchPlaceHolder,
  selectedItem,
  ...props
}) => {
  const {
    state: { query },
  } = useContext(SearchContext)

  const datasetSearch = useMemo(() => {
    if (!searchKeys) return null
    return freetextSearch(items, {
      threshold: 0.15,
      location: 0,
      distance: 30,
      keys: searchKeys,
      includeScore: true,
    })
  }, [items, searchKeys])

  const dataset = useMemo(() => {
    if (includeSearch && datasetSearch && query.length >= 2) {
      return datasetSearch.search(query).map((x) => x.item)
    }
    return items
  }, [query, items, datasetSearch, includeSearch])

  return (
    <div {...props}>
      {includeSearch && datasetSearch && (
        <div tw="sticky top-0 bg-white p-4 px-8 z-50">
          <Search dataset={datasetSearch} placeholder={searchPlaceHolder} />
        </div>
      )}
      <ul tw="m-0 list-none p-0 border border-solid border-0 border-t border-brand-gray-brand">
        {dataset.map((x) => {
          const description =
            typeof descriptionKey === "object"
              ? descriptionKey
                  .map((k) =>
                    x[k] && typeof x[k] === "object" ? x[k].join(", ") : x[k]
                  )
                  .filter(Boolean)
                  .join(`${descriptionKeySeparator}`)
              : descriptionKey
              ? x[descriptionKey]
              : null
          const secondaryDescription =
            typeof secondaryDescriptionKey === "object"
              ? secondaryDescriptionKey
                  .map((k, i) => {
                    let item = secondaryDescriptionKeyFormatFnc
                      ? secondaryDescriptionKeyFormatFnc(x, k)
                      : x[k]
                    if (
                      typeof item !== "object" &&
                      i !== secondaryDescriptionKey.length - 1
                    ) {
                      item += `${secondaryDescriptionKeySeparator}`
                    }
                    return item
                  })
                  .filter(Boolean)
              : // .join(`${secondaryDescriptionKeySeparator}`)
              secondaryDescriptionKey
              ? secondaryDescriptionKeyFormatFnc
                ? secondaryDescriptionKeyFormatFnc(x[secondaryDescriptionKey])
                : x[secondaryDescriptionKey]
              : null
          const tertiaryDescription =
            typeof tertiaryDescriptionKey === "object"
              ? tertiaryDescriptionKey
                  .map((k) =>
                    tertiaryDescriptionKeyFormatFnc
                      ? tertiaryDescriptionKeyFormatFnc(x, k)
                      : x[k]
                  )
                  .filter(Boolean)
                  .join(`${tertiaryDescriptionKeySeparator}`)
              : tertiaryDescriptionKey
              ? tertiaryDescriptionKeyFormatFnc
                ? tertiaryDescriptionKeyFormatFnc(x[tertiaryDescriptionKey])
                : x[tertiaryDescriptionKey]
              : null

          const isSelected = selectedItem ? selectedItem.id === x.id : false
          return (
            <li
              key={x.id}
              tw="px-8 py-6 border border-solid border-0 border-b border-brand-gray-brand transition-all cursor-pointer relative"
              css={!isSelected && selectedItem ? tw`opacity-50` : ``}
              onClick={onItemSelect ? () => onItemSelect(x) : () => ({})}
            >
              <span tw="text-2xl" css={titleStyling}>
                {titleIcon ? (
                  <Icon
                    css={titleIconStyling ? titleIconStyling : null}
                    icon={titleIcon}
                  />
                ) : null}
                {x[titleKey]}
              </span>
              {description && (
                <span tw="block mt-2 text-xl opacity-60">{description}</span>
              )}
              {secondaryDescription && (
                <span
                  tw="block mt-2 text-xl opacity-60 flex items-center"
                  css={secondaryDescriptionStyling}
                >
                  {secondaryDescription}
                </span>
              )}
              {tertiaryDescription && (
                <span
                  tw="block mt-2 text-xl opacity-60"
                  css={tertiaryDescriptionStyling}
                >
                  {tertiaryDescription}
                </span>
              )}
              {isSelected && (
                <Icon
                  tw="absolute right-4 top-12 justify-self-center"
                  icon="chevron-right"
                  size={IconSizeEnum.SM}
                />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
