import { useFormData } from "hooks/useFormData"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { DeviceConfiguration } from "../types"
import tw from "twin.macro"
import { ConfigurationContext } from "../context"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  ValidationInput,
} from "@clevertrack/shared"
import { useDeviceConfiguration } from "../hooks"
import styled from "styled-components"
import { DevicesContext } from "app/Devices/context"
import { Select } from "lib/Select"
import { DisplayKeys, SelectedDisplayKeys } from "./DisplayKeys"
import { DeviceFeatures } from "./Features"
import { ContextMenu } from "app/ContextMenu"
import { IconSizeEnum } from "lib/Icon"

type ConfigurationFormType = {
  onSave: (config: DeviceConfiguration) => void
}

enum ExtraFieldsets {
  DisplayKeys = "DisplayKeys",
  Features = "Features",
}

const StyledWrapper = styled.div`
  ${tw`w-full h-full space-x-4 p-4`}
  display: grid;
  grid-template-columns: 40rem 1fr;
`

export const ConfigurationForm: React.FC<ConfigurationFormType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { configuration },
  } = useContext(ConfigurationContext)
  const [toggledFieldset, setToggledFieldset] = useState<ExtraFieldsets | null>(
    null
  )
  const {
    state: { files },
  } = useContext(DevicesContext)
  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceConfiguration
  >(null)
  const {
    updateConfigByID,
    deleteConfig,
    clearConfig,
    duplicateConfig,
  } = useDeviceConfiguration()

  useEffect(() => {
    setCurrentModel(configuration)
  }, [configuration])

  useEffect(() => {
    if (currentModel) updateConfigByID(currentModel)
  }, [currentModel])

  const [availableConfigs, selectedConfig] = useMemo(() => {
    const avail = files.map((x) => ({
      label: x.name,
      value: x.id,
    }))
    const selected = avail.find(
      (x) => x.value === configuration?.configurationFile?.value
    )
    return [avail, selected]
  }, [files, configuration])

  const onSubmit = () => {
    if (currentModel) {
      onSave(currentModel)
    }
  }

  const onCancel = () => {
    clearConfig()
  }

  const onDeleteHandler = async () => {
    if (configuration) await deleteConfig(configuration.id)
  }

  const onDuplicateHandler = async () => {
    if (configuration) await duplicateConfig(configuration)
  }

  if (!currentModel) return null

  return (
    <StyledWrapper>
      <Form onSubmit={onSubmit}>
        <div tw="bg-white p-4">
          <div tw="flex items-center justify-between mb-4">
            <h3 tw="m-0">Oplysninger</h3>
            <ContextMenu>
              <ul tw="list-none p-2 px-2 m-0">
                {/* <li tw="opacity-60 block pb-2 mt-2 text-lg text-brand-gray-base truncate overflow-hidden">
                  Handlinger
                </li> */}
                <li
                  tw="cursor-pointer transition-all flex px-4 py-4 items-center space-x-4 hover:(bg-brand-gray-brand)"
                  onClick={onDuplicateHandler}
                >
                  <Icon icon="copy" size={IconSizeEnum.MD} />
                  <span>Kopiér til ny konfiguration</span>
                </li>
                <li
                  tw="cursor-pointer transition-all flex px-4 py-4 items-center space-x-4 hover:(bg-brand-gray-brand)"
                  onClick={onDeleteHandler}
                >
                  <Icon icon="trash-alt" size={IconSizeEnum.MD} />
                  <span>Slet konfiguration</span>
                </li>
              </ul>
            </ContextMenu>
          </div>

          <div tw="space-y-8">
            <FormField label="Navn" validationKey="name">
              <ValidationInput
                id="name"
                value={currentModel.name}
                placeholder="Konfigurationens navn"
                onChange={(e) => updateModel("name", e.target.value)}
              />
            </FormField>
            <FormField label="Konfiguration (fra Teltonika FOTA)">
              <Select
                menuPlacement="auto"
                placeholder="Vælg konfiguration"
                options={availableConfigs}
                onChange={(opt) => {
                  updateModel("configurationFile", opt)
                  updateModel("tag", opt.label)
                }}
                value={selectedConfig}
                tw="pt-4"
              />
            </FormField>
            <FormField
              label="Tag (til Power BI filtrering)"
              validationKey="tag"
            >
              <ValidationInput
                id="tag"
                disabled
                value={currentModel.tag}
                placeholder="Tag"
              />
            </FormField>
            <span
              tw="flex my-8 px-4 items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
              onClick={() =>
                setToggledFieldset((prev) =>
                  prev === ExtraFieldsets.DisplayKeys
                    ? null
                    : ExtraFieldsets.DisplayKeys
                )
              }
            >
              {toggledFieldset === ExtraFieldsets.DisplayKeys ? (
                `Gem display keys`
              ) : (
                <SelectedDisplayKeys />
              )}
            </span>
            <span
              tw="flex my-8 px-4 items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
              onClick={() =>
                setToggledFieldset((prev) =>
                  prev === ExtraFieldsets.Features
                    ? null
                    : ExtraFieldsets.Features
                )
              }
            >
              {toggledFieldset === ExtraFieldsets.Features
                ? `Gem opsætning`
                : `Vælg opsætning +`}
            </span>
          </div>
          <ButtonGroup tw="bg-white pt-0 mt-16 z-200">
            <Button type="button" variant="cancel" onClick={onCancel}>
              <span tw="flex items-center">
                <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
                <span tw="text-xl font-normal">Tilbage</span>
              </span>
            </Button>
            <Button type="submit" variant="primary">
              Gem konfiguration
            </Button>
          </ButtonGroup>
        </div>
      </Form>
      {toggledFieldset === ExtraFieldsets.DisplayKeys && <DisplayKeys />}
      {toggledFieldset === ExtraFieldsets.Features && <DeviceFeatures />}
    </StyledWrapper>
  )
}
