import React, { useReducer, createContext } from "react"
import { DeviceConfiguration } from "./types"
import { ConfigurationActionMap, ConfigurationTypes } from "./actions"
import { updateCollectionItemByIndex } from "utils/array"
import orderBy from "lodash-es/orderBy"
import { ApiDisplayKey } from "app/Devices/types"

type InitialConfigurationStateType = {
  configuration: DeviceConfiguration | null
  configurationList: DeviceConfiguration[] | []
  availableDisplayKeys: ApiDisplayKey[] | []
}

const initialState: InitialConfigurationStateType = {
  configuration: null,
  configurationList: [],
  availableDisplayKeys: [],
}

const ConfigurationContext = createContext<{
  state: InitialConfigurationStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

const reducer = (
  state: InitialConfigurationStateType,
  action: ConfigurationActionMap
) => {
  switch (action.type) {
    case ConfigurationTypes.SetConfiguration:
      return { ...state, configuration: action.payload.configuration }
    case ConfigurationTypes.SetConfigurations:
      return {
        ...state,
        configurationList: orderBy(
          action.payload.configurationList,
          "name",
          "asc"
        ),
      }
    case ConfigurationTypes.AddConfiguration:
      return {
        ...state,
        configurationList: orderBy(
          [...state.configurationList, action.payload.configuration],
          "name",
          "asc"
        ),
      }
    case ConfigurationTypes.SetAvailableDisplayKeys:
      return {
        ...state,
        availableDisplayKeys: action.payload.displayKeys,
      }
    case ConfigurationTypes.UpdateConfigurationByID:
      if (state.configurationList) {
        const newConfigs = updateCollectionItemByIndex<DeviceConfiguration>(
          state.configurationList,
          action.payload.configuration.id,
          action.payload.configuration,
          "id"
        )
        return {
          ...state,
          configuration: action.payload.configuration,
          configurationList: orderBy(newConfigs, "name"),
        }
      }
      return state
    case ConfigurationTypes.DeleteConfigurationByID:
      const newConfigs = state.configurationList.filter(
        (config) => config.id !== action.payload.configID
      )
      if (state.configuration?.id === action.payload.configID) {
        return {
          ...state,
          configuration: null,
          configurationList: newConfigs,
        }
      }
      return {
        ...state,
        configurationList: newConfigs,
      }
    case ConfigurationTypes.ClearConfiguration:
      return {
        ...state,
        configuration: null,
      }
    default:
      return state
  }
}

const ConfigurationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ConfigurationContext.Provider value={{ state, dispatch }}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export { ConfigurationContext, ConfigurationProvider }
