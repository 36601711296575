import React, { useState } from "react"
import cogoToast from "@clevertrackdk/cogo-toast"
import { AccountForm } from "app/Account/Form"
import { MainContainer } from "app/MainContainer"
import { Account, ClientApiAccount } from "app/Account/types"
import { useFirestoreCompany } from "services/firestore/company"
import { createAccount } from "services/accounts"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { navigate } from "gatsby"
import { StyledFormGrid } from "app/Grid/FormGrid"

export const CreateAccount: React.FC = ({ ...props }) => {
  const [newAccount, setNewAccount] = useState<Account | unknown>({})
  const { getApiAccounts } = useBackgroundDataFetch()
  const { createFirebaseCompany } = useFirestoreCompany()

  const onSaveHandler = async (newAccount) => {
    try {
      const apiResult = await createAccount(newAccount)
      if (apiResult.result === "OK" && apiResult?.accounts) {
        const company = apiResult.accounts[0] as ClientApiAccount
        const accountData: Account = {
          id: company.id.toString(),
          vatNumber:
            newAccount.vatNumber === undefined ? null : newAccount.vatNumber,
          name: newAccount.name,
          email: newAccount.email,
          description: newAccount.description,
          features: newAccount.features ?? {},
          functions: newAccount.functions ?? {},
          installerAccountFeatures: newAccount.installerAccountFeatures ?? [],
          msisdn: newAccount.msisdn === undefined ? null : newAccount.msisdn,
        }
        const res = await createFirebaseCompany(
          accountData,
          company.id.toString()
        )

        if (res === "OK") {
          cogoToast.success("Ny konto oprettet")
          await getApiAccounts()
          navigate(`/app/accounts/${company.id.toString()}`)
        }
      }
    } catch (error) {
      console.log(error)
      cogoToast.error("Kunne ikke oprette konto")
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>{"Opret ny konto"}</h2>
        </>
      }
    >
      <div>
        {newAccount && (
          <AccountForm
            account={newAccount}
            onSubmit={onSaveHandler}
            basicTitle="Stamdata"
            settingsTitle="Opsætning"
            saveButtonText="Opret konto"
            showSettings
          />
        )}
      </div>
    </MainContainer>
  )
}
