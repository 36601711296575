export const basicIdleReadInputOptions = [
  {
    label: "Ingen tomgangsmåling",
    value: 8,
  },
  {
    label: "GPS Excessive idling",
    value: 0,
  },
  {
    label: "CAN Motoromdrejninger (RPM)",
    value: 1,
  },
  {
    label: "FMS Motoromdrejninger (RPM)",
    value: 3,
  },
  {
    label: "Trykføler",
    value: 2,
  },
  {
    label: "Motorbelastning",
    value: 4,
  },
]

export const rpmIdleReadInputOptions = [
  {
    label: "...GPS hastighed = 0, excessive idle = 1",
    value: 6,
  },
  {
    label: "...excessive idle = 1, CAN hastighed = 0",
    value: 5,
  },
  {
    label: "...CAN hastighed = 0",
    value: 7,
  },
]

export const idleReadInputOptions = [
  {
    label: "Generelle",
    options: basicIdleReadInputOptions,
  },
  {
    label: "RPM over 0 og...",
    options: rpmIdleReadInputOptions,
  },
]

export const engineHourInputOptions = [
  {
    label: "GPS Ignition (Bruges sammen med trykføler)",
    value: 0,
  },
  {
    label: "CAN Total Engine Hours",
    value: 1,
  },
  {
    label: "CAN Engine Hours Counted",
    value: 2,
  },
  {
    label:
      "CAN Engine Hours Counted (bruger total engine hours hvis tilgængelig)",
    value: 6,
  },
  {
    label: "FMS Engine Total Hours",
    value: 3,
  },
  {
    label:
      "Motoromdrejniner > 0 og tænding er på (bruger total engine hours hvis tilgængelig)",
    value: 4,
  },
]

export const fuelLitreInputOptions = [
  {
    label: "Ingen brændstofsmåling",
    value: 4,
  },
  {
    label: "CAN Fuel Total",
    value: 0,
  },
  {
    label: "CAN Fuel Count",
    value: 1,
  },
  {
    label: "FMS Fuel Total",
    value: 2,
  },
  {
    label: "AEMP Fuel Total",
    value: 3,
  },
  {
    label: "LLS 1 Fuel Level",
    value: 5,
  },
  {
    label: "TCP Bin Buffer",
    value: 6,
  },
]

export const mileageInputOptions = [
  {
    label: "GPS Odometer",
    value: 0,
  },
  {
    label: "CAN Mileage Total",
    value: 1,
  },
  {
    label: "CAN Mileage Count",
    value: 2,
  },
  {
    label: "FMS Total Odometer",
    value: 1,
  },
]
