import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import tw from "twin.macro"
import { default as Icon } from "../../Icon"
import { ITapBarProps } from "../navigation.types"
import { FeatureComponent } from "app/FeatureComponent"
import { UserContext } from "app/User/context"
import { AppContext } from "context"

const StyledTapBar = styled.nav`
  position: fixed;
  bottom: 0;
  z-index: 1000;
  ${tw`bg-white border-solid border-0 border-t border-gray-200 py-2`}
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14%, 3.6rem));
    justify-content: space-evenly;

    .item {
      a {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 1rem 1rem 0.5rem;
        border-bottom: 4px solid transparent;
        transition: all ease-out 0.2s;

        &.active {
          border-bottom-color: currentColor;
          ${tw`text-brand-500`};
        }

        &:focus,
        &.active {
        }

        svg {
          transition: fill ease-out 0.2s;
          fill: currentColor;
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
`

const TapBar: React.FC<ITapBarProps> = React.memo(
  ({ items, children, onNavToggle, ...props }) => {
    const {
      state: { user },
    } = useContext(UserContext)
    const {
      state: { toggledMenus },
    } = useContext(AppContext)

    if (!user?.id) return null

    const renderLink = ({
      link,
      icon,
      title,
      render,
      onClick,
      partiallyActive,
      isNavToggle,
      activeOnKey,
      ...itemProps
    }) => {
      return (
        <li className="item" {...itemProps}>
          {link ? (
            <Link
              to={link}
              onClick={isNavToggle ? onNavToggle : onClick}
              activeClassName="active"
              partiallyActive={partiallyActive}
            >
              {render ? (
                <>
                  {render()}
                  <span tw="text-lg">{title}</span>
                </>
              ) : (
                <>
                  {icon && <Icon icon={icon} />}
                  <span tw="text-lg">{title}</span>
                </>
              )}
            </Link>
          ) : (
            <a
              onClick={isNavToggle ? onNavToggle : onClick}
              className={`${
                toggledMenus.includes(activeOnKey) ? "active" : ""
              }`}
            >
              {render ? (
                <>
                  {render()}
                  <span tw="text-lg">{title}</span>
                </>
              ) : (
                <>
                  {icon && <Icon icon={icon} />}
                  <span tw="text-lg">{title}</span>
                </>
              )}
            </a>
          )}
        </li>
      )
    }
    return (
      <StyledTapBar tw="px-4 w-full" {...props}>
        <ul tw="py-4">
          {items &&
            items.map(({ key, featureFlag, ...linkProps }) => {
              return featureFlag ? (
                <FeatureComponent key={key} feature={featureFlag} user={user}>
                  {renderLink(linkProps)}
                </FeatureComponent>
              ) : (
                renderLink(linkProps)
              )
            })}
        </ul>
      </StyledTapBar>
    )
  }
)

export { TapBar }
