import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import "react-vis/dist/style.css"
import { RadialChart } from "react-vis"

const StyledDonutMetric = styled.article`
  ${tw`relative bg-white shadow-xl`}

  display: grid;
  grid-template-rows: auto auto;

  header {
    ${tw`border-solid border-0 border-b border-brand-gray-brand px-8 py-4`}
    h2 {
      ${tw`text-3xl m-0`}
      font-family: ${(props) => props.theme.fontStacks.subheading};
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 2rem;
    align-items: center;
    ${tw`px-8 py-4`}
  }

  .legend {
    ul {
      list-style: none;
      ${tw`p-0`}
      display: grid;
      column-gap: 4rem;
      row-gap: 2rem;
      grid-template-columns: repeat(2, 1fr);

      li {
        ${tw`text-2xl`}

        .color {
          content: "";
          display: block;
          ${tw`bg-current w-6 h-6 rounded-full mr-4`}
        }

        .kpiValue {
          font-family: ${(props) => props.theme.fontStacks.bold};
          font-size: 1.6rem;
          ${tw`m-0 block flex items-center`}
        }

        .kpiPercent {
          font-family: ${(props) => props.theme.fontStacks.light};
          ${tw`m-0 mt-4 text-4xl block`}
        }
      }
    }
  }

  .chart {
    width: 24rem;
    height: 24rem;
  }
`

export const DonutMetric: React.FC = ({ title, series, ...props }) => {
  return (
    <StyledDonutMetric>
      <header>
        <h2>{title}</h2>
      </header>
      <div className="content">
        <div className="legend">
          <ul>
            {series.legend.map((item) => {
              return (
                <li key={item.id}>
                  <span className="kpiValue">
                    <span className="color" css={item.style} />
                    {item.label}
                  </span>
                  <span className="kpiPercent">{item.data}</span>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="chart">
          <RadialChart
            data={series.series}
            width={240}
            height={240}
            // padAngle={0.15}
            radius={100}
            innerRadius={80}
            animation={true}
          />
        </div>
      </div>
    </StyledDonutMetric>
  )
}
