import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  doc,
  collection,
  updateDoc,
  serverTimestamp,
  getDocs,
  setDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore"
import { Account } from "app/Account/types"
import { DeviceConfiguration } from "app/Configuration/types"

export const useFirestoreDeviceConfigurations = () => {
  const { db } = useContext(FirebaseContext)

  const getConfigurations = async () => {
    if (db) {
      try {
        const firebaseCfgs = collection(db, `configurations`)

        const res = await getDocs(firebaseCfgs)

        return {
          status: "OK",
          configs: res.docs.map((doc) => doc.data()),
        }
      } catch (error) {
        return {
          status: "Error",
          error,
        }
      }
    }
  }

  const createConfiguration = async (
    config: DeviceConfiguration,
    id: string
  ) => {
    if (db) {
      try {
        const createdConfig = {
          ...config,
          created: +new Date(),
        }
        await setDoc(doc(db, "configurations", id), createdConfig)

        return {
          status: "OK",
          config: createdConfig,
        }
      } catch (error) {
        return {
          status: "Error",
          error,
        }
      }
    }
  }

  const saveConfiguration = async (config: DeviceConfiguration, id: string) => {
    if (db) {
      const firebaseCfg = doc(db, `configurations`, id)
      try {
        const updatedConfig = {
          ...config,
          updated: +new Date(),
        }
        await updateDoc(firebaseCfg, updatedConfig)

        return {
          status: "OK",
          config: updatedConfig,
        }
      } catch (error) {
        return {
          status: "Error",
          error,
        }
      }
    }
  }

  const deleteConfiguration = async (id: string) => {
    if (db) {
      const firebaseCfg = doc(db, `configurations`, id)
      try {
        await deleteDoc(firebaseCfg)

        return {
          status: "OK",
        }
      } catch (error) {
        return {
          status: "Error",
          error,
        }
      }
    }
  }

  return {
    getConfigurations,
    createConfiguration,
    saveConfiguration,
    deleteConfiguration,
  }
}
