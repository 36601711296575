import { UserContext } from "app/User/context"
import { useCallback, useContext } from "react"
import { UserTypeEnum, UserTypes } from "app/User/types"
import { ApiAccountTypeEnum } from "app/Account/types"
import { AccountsContext } from "app/Account/context"

export const usePermissions = () => {
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { account },
  } = useContext(AccountsContext)

  const hasAccess = useCallback(
    (
      allowedUserRoles: UserTypeEnum[] = [],
      allowedUserFlags: (keyof UserTypes)[] = []
    ) => {
      if (user) {
        if (user?.is_cms_admin) return true
        if (allowedUserRoles.some((x) => user?.userTypes?.includes(x)))
          return true
        if (
          allowedUserFlags.find((key) => user[key] === 1 || user[key] === true)
        )
          return true
        return false
      }
      return false
    },
    [user]
  )

  const hasAccountAccess = useCallback(
    (allowedAccountTypes: ApiAccountTypeEnum[]) => {
      if (allowedAccountTypes.length === 0 || user?.is_cms_admin) {
        return true
      } else {
        return allowedAccountTypes.some((value) =>
          account?.description.includes(value)
        )
      }
    },
    [account, user]
  )

  return { hasAccess, hasAccountAccess }
}
