import { FirebaseContext } from "context/FirebaseProvider"
import { useContext } from "react"

export const useVertexAI = () => {
  const { vertexAI } = useContext(FirebaseContext)

  const fileToGenerativePart = async (file) => {
    const base64EncodedDataPromise = new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result.split(",")[1])
      reader.readAsDataURL(file)
    })
    return {
      inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
    }
  }

  const generateJSONFromImage = async (prompt, file) => {
    try {
      if (vertexAI) {
        // Provide a text prompt to include with the image

        // Prepare image for input
        const imagePart = await fileToGenerativePart(file)

        // To stream generated text output, call generateContentStream with the text and image
        const result = await vertexAI.generateContent([prompt, imagePart])

        console.dir(result)

        return result.response.text()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return { generateJSONFromImage }
}
