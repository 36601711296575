import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import "twin.macro"
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Responsive,
} from "@clevertrack/shared"
import EmailForm from "app/EmailForm"
// import { resetPassword } from "services/public"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"
import { resetPassword } from "services/users"

const StyledResetPassword = styled.div`
  width: 80vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.media.tablet_portrait_up`
    margin: 2rem auto 0;
    max-width: 60vw;
  `}

  ${(props) => props.theme.media.tablet_portrait_up`
    max-width: 60rem;
  `}

  ${(props) => props.theme.media.tablet_landscape_up`
    max-width: 75%;
  `}
`

const StyledCard = styled(Card)`
  margin-top: 2rem;
`

const SysMsg = styled.small`
  color: ${(props) => props.theme.colors[props.color]};
`

function ResetPassword({ ...props }) {
  const [sysMsg, setSysMsg] = useState({
    content: "Vi sender et link til nulstilling",
    color: null,
  })

  const submitHandler = async (e) => {
    const { value } = e.target.querySelector("input")
    try {
      await resetPassword({ email: value })
      setSysMsg({
        content: `Vi har sendt en email ✔`,
        color: "success",
      })
    } catch (err) {
      console.log(err)
    }
  }

  const goBackHandler = () => {
    navigate("/")
  }

  return (
    <SignedOutWrapper>
      <SEO title="Nulstil password" slug="/app/reset-password" />
      <StyledResetPassword>
        <h2>Genopret konto</h2>
        <Form tw="w-full" onSubmit={submitHandler}>
          <EmailForm />
          <Responsive
            tabletPortrait={
              <ButtonGroup position="between" tw="px-0">
                <Button
                  type="button"
                  onClick={goBackHandler}
                  variant="transparent"
                >
                  Gå tilbage
                </Button>
                <Button type="submit" variant="primary">
                  Bekræft
                </Button>
              </ButtonGroup>
            }
            phone={
              <ButtonGroup direction="column">
                <Button type="submit" variant="primary">
                  Bekræft
                </Button>
                <Button
                  type="button"
                  onClick={goBackHandler}
                  variant="transparent"
                >
                  Tilbage
                </Button>
              </ButtonGroup>
            }
          />
        </Form>
      </StyledResetPassword>
    </SignedOutWrapper>
  )
}

export default ResetPassword

ResetPassword.defaultProps = {}

ResetPassword.propTypes = {}
