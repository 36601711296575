import React, { useMemo } from "react"
import { useParams } from "@gatsbyjs/reach-router"
import cogoToast from "@clevertrackdk/cogo-toast"
import { MainContainer } from "app/MainContainer"
import { StyledFormGrid } from "app/Grid/FormGrid"
import { UserForm } from "app/User/Form"
import { useFirestoreUser } from "services/firestore/user"
import { UserTypes } from "app/User/types"
import { createUser } from "services/users"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { navigate } from "gatsby"

export const CreateUser: React.FC = ({ ...props }) => {
  const { accountID } = useParams()
  const { getApiUsers } = useBackgroundDataFetch()

  const newUser = useMemo<Partial<UserTypes>>(() => {
    if (accountID) {
      return {
        active: 1,
        account_id: +accountID,
      }
    }
    return {
      active: 1,
    }
  }, [accountID])

  const { saveFirebaseUser } = useFirestoreUser()

  const onSaveHandler = async (user: UserTypes) => {
    try {
      const apiResult = await createUser(user, user.account_id)

      if (apiResult.result === "OK" && apiResult.user) {
        const newlyCreatedUser = apiResult.user
        const fbResult = await saveFirebaseUser(
          {
            id: newlyCreatedUser.id,
            userTypes: user.userTypes,
            feature: user.feature ?? [],
            account: user.account,
            firstName: user.firstName,
            email: user.email,
            accountID: user.account_id,
            language: user.language ?? "da-dk",
            validationToken: newlyCreatedUser.token,
            isAccountAdmin:
              typeof user.isAccountAdmin !== "undefined"
                ? user.isAccountAdmin
                : false,
          },
          newlyCreatedUser.id
        )

        if (fbResult && apiResult.result === "OK") {
          cogoToast.success("Ny bruger oprettet!")
          await getApiUsers()
          navigate(`/app/users/${apiResult.user?.id.toString()}`)
        }
      } else {
        cogoToast.error("Brugeren eksisterer allerede.")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke gemme bruger")
    }
  }

  return (
    <MainContainer
      tw="h-full"
      header={
        <>
          <h2>Opret ny bruger</h2>
        </>
      }
    >
      <div>
        {newUser && (
          <UserForm
            user={newUser}
            onSubmit={onSaveHandler}
            basicTitle="Oplysninger"
            settingsTitle="Opsætning"
            saveButtonText="Opret ny bruger"
            showSettings
          />
        )}
      </div>
    </MainContainer>
  )
}
