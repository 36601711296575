import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import React from "react"
import "twin.macro"

type EmptyListType = {
  icon: string
  content: string
  action: React.ReactElement
}

export const EmptyList: React.FC<EmptyListType> = ({
  icon,
  content,
  action,
  ...props
}) => {
  return (
    <div tw="w-full h-full flex items-center justify-center">
      <div tw="text-center text-brand-gray-base">
        <Icon icon={icon} size={IconSizeEnum.XL} />
        <p tw="text-xl">{content}</p>
        {action}
      </div>
    </div>
  )
}
