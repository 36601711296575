import React, { useContext, useEffect } from "react"
import { UserContext } from "app/User/context"
import "twin.macro"
import { useAccounts } from "app/Account/hooks"
import { Item } from "lib/List/Item"
import { Group } from "lib/List/Group"
import { DataList } from "app/DataList"
import { SearchProvider } from "app/Search/context"
import { InstallationContext } from "app/Installation/context"
import {
  InstallationActions,
  InstallationTypes,
} from "app/Installation/actions"
import { Account } from "app/Account/types"
import { Installation } from "app/Installation"
import { AccountsContext } from "app/Account/context"
import { useUser } from "app/User/hooks"
import { DevicesContext } from "app/Devices/context"
import { InstallationFlow } from "app/Installation/Flow"
import { Helmet } from "react-helmet"
import orderBy from "lodash-es/orderBy"

export const InstallerHome: React.FC = ({ ...props }) => {
  const {
    state: { user },
  } = useContext(UserContext)
  const { dispatch } = useContext(InstallationContext)
  const {
    state: { account },
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)
  const { assignedAccounts } = useAccounts()
  const { currentUserIsExternalFitter } = useUser()

  const onSelectAccountHandler = (item: Account) => {
    dispatch(
      InstallationActions(InstallationTypes.SetAccount, { account: item })
    )
  }

  useEffect(() => {
    if (currentUserIsExternalFitter && account && devices.length > 0) {
      onSelectAccountHandler(account)
    }
  }, [currentUserIsExternalFitter, account, devices.length])

  return (
    <>
      <Helmet title="Enhedsmontering | Clevertrack" />
      <div tw="pb-28">
        <div tw="p-8">
          <h2 tw="m-0">Hej {user?.firstName}</h2>
          <p tw="m-0 mt-2 text-2xl">Hvilken konto skal du arbejde på i dag?</p>
        </div>
        <SearchProvider>
          <DataList
            items={orderBy(assignedAccounts, "name")}
            titleKey="name"
            includeSearch
            searchKeys={["name"]}
            onItemSelect={onSelectAccountHandler}
            searchPlaceHolder="Søg efter konto"
          />
        </SearchProvider>
        <SearchProvider>
          <Installation />
        </SearchProvider>
        <InstallationFlow />
      </div>
    </>
  )
}
