import React, { useCallback, useContext, useMemo, useState } from "react"
import {
  Button,
  ButtonGroup,
  Form,
  FormContext,
  FormField,
  Tag,
  ValidationInput,
} from "@clevertrack/shared"
import tw from "twin.macro"
import styled from "styled-components"
import { Account, ApiAccountTypeEnum } from "../types"
import { getCVRInfo } from "services/integrations/cvrapi"
import { useFormData } from "hooks/useFormData"
import { Select } from "lib/Select"
import {
  accountFeatureOptions,
  accountFunctionsOptions,
  accountType,
  installerAccountFeatureOptions,
} from "../helper"
import isEqual from "lodash-es/isEqual"
import Checkbox from "lib/Checkbox"
import { navigate } from "gatsby"
import { FeatureComponent } from "app/FeatureComponent"
import { UserTypeEnum } from "app/User/types"
import { StyledFormGrid } from "app/Grid/FormGrid"
import uniq from "lodash-es/uniq"

const StyledListItem = styled.li`
  ${tw`flex items-center justify-between`}
`

const StyledForm = styled(Form)`
  padding-bottom: 6.4rem;

  .group {
    ${tw`p-8 lg:(p-0)`}
  }

  ${(props) => props.theme.media.tablet_landscape_up`
    column-gap: 4rem;
  `}
`

type AccountFormProps = {
  account: Account | unknown
  onSubmit: (account: Account) => void
  saveButtonText?: string
  showSettings?: boolean
  basicTitle?: string
  settingsTitle?: string
}

export const AccountForm: React.FC<AccountFormProps> = ({
  account,
  onSubmit,
  saveButtonText = "Gem",
  showSettings,
  basicTitle = "Stamdata",
  settingsTitle = "Opsætning",
  ...props
}) => {
  const { state } = useContext(FormContext)
  const [suggestedCvrInfo, setSuggestedCvrInfo] = useState(null)
  const [
    currentAccount,
    updateModel,
    resetModel,
    setCurrentAccount,
  ] = useFormData<Account>(account)

  const [
    defaultAccountFunctions,
    defaultAccountFeatures,
    defaultInstallerAccountFeatures,
  ] = useMemo(() => {
    return [
      currentAccount.functions
        ? accountFunctionsOptions.filter((opt) => {
            return Object.keys(currentAccount.functions).includes(
              Object.keys(opt.value)[0]
            )
          })
        : null,
      currentAccount.features
        ? accountFeatureOptions.filter((opt) => {
            return Object.keys(currentAccount.features).includes(
              Object.keys(opt.value)[0]
            )
          })
        : null,
      currentAccount?.installerAccountFeatures
        ? installerAccountFeatureOptions.filter((opt) => {
            return currentAccount?.installerAccountFeatures?.includes(opt.value)
          })
        : null,
    ]
  }, [currentAccount])

  const onCvrChangeHandler = async (e) => {
    if (e.target.value.length === 8 && !suggestedCvrInfo?.error) {
      const result = await getCVRInfo(e.target.value)
      setSuggestedCvrInfo(result.data)
    }

    updateModel("vatNumber", e.target.value)
  }

  const onMapCvrDataToAccount = () => {
    updateModel("name", suggestedCvrInfo.name)
    updateModel("city", suggestedCvrInfo.city)
    updateModel("zip", suggestedCvrInfo.zipcode)
    updateModel("address", suggestedCvrInfo.address)
    if (suggestedCvrInfo.phone) updateModel("phone", suggestedCvrInfo.phone)
    if (suggestedCvrInfo.email) updateModel("email", suggestedCvrInfo.email)

    setSuggestedCvrInfo(null)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    if (onSubmit) onSubmit(currentAccount)
  }

  return (
    <StyledForm onSubmit={onSubmitHandler} {...props}>
      <StyledFormGrid columns="1fr 1fr 1fr">
        <div className="group">
          <h3 tw="m-0 mb-8">{basicTitle}</h3>
          <FormField label="CVR" validationKey="vatNumber">
            <ValidationInput
              id="vatNumber"
              defaultValue={currentAccount.vatNumber}
              placeholder="Indtast CVR nr."
              onChange={onCvrChangeHandler}
            />
          </FormField>
          <FormField label="Virksomhed" validationKey="name">
            <ValidationInput
              required
              id="name"
              defaultValue={currentAccount.name}
              placeholder="Indtast virksomhedens navn"
              onChange={(e) => updateModel("name", e.target.value)}
            />
          </FormField>
          <FormField label="Adresse" validationKey="address">
            <ValidationInput
              required
              id="address"
              defaultValue={currentAccount.address}
              placeholder="Indtast adresse"
              onChange={(e) => updateModel("address", e.target.value)}
            />
          </FormField>
          <div tw="flex items-center space-x-4 space-y-0 my-8">
            <FormField tw="w-1/3" label="Postnr." validationKey="zip">
              <ValidationInput
                required
                id="zip"
                defaultValue={currentAccount.zip}
                placeholder="Indtast postnr."
                onChange={(e) => updateModel("zip", e.target.value)}
              />
            </FormField>
            <FormField label="By" validationKey="city">
              <ValidationInput
                required
                id="city"
                defaultValue={currentAccount.city}
                placeholder="Indtast by"
                onChange={(e) => updateModel("city", e.target.value)}
              />
            </FormField>
          </div>
          <FormField label="Hovedtelefon" validationKey="phone">
            <ValidationInput
              required
              id="phone"
              defaultValue={currentAccount.phone}
              placeholder="Indtast hovedtelefon"
              onChange={(e) => updateModel("phone", e.target.value)}
            />
          </FormField>
          <FormField label="Email" validationKey="email">
            <ValidationInput
              required
              id="email"
              defaultValue={currentAccount.email}
              placeholder="Indtast email"
              onChange={(e) => updateModel("email", e.target.value)}
            />
          </FormField>
          <FormField label="Primære kontakt" validationKey="contact">
            <ValidationInput
              id="contact"
              defaultValue={currentAccount.contact}
              placeholder="Indtast navn"
              onChange={(e) => updateModel("contact", e.target.value)}
            />
          </FormField>
          <FormField
            label="Primære kontakt telefon"
            validationKey="contactPhone"
          >
            <ValidationInput
              id="contactPhone"
              defaultValue={currentAccount.contactPhone}
              placeholder="Indtast telefonnr."
              onChange={(e) => updateModel("contactPhone", e.target.value)}
            />
          </FormField>
          {suggestedCvrInfo && !suggestedCvrInfo.error && (
            <div tw="bg-brand-gray-brand p-4 mt-8">
              <h4 tw="my-0 mb-4 flex items-center justify-between">
                Data fra CVR opslag{" "}
                <span
                  onClick={() => onMapCvrDataToAccount()}
                  tw="block ml-auto text-brand-500 font-normal cursor-pointer"
                >
                  Anvend data
                </span>
              </h4>
              {suggestedCvrInfo.creditbankrupt && (
                <div tw="flex items-center mt-2">
                  <Tag tw="bg-brand-red-300 m-0" show>
                    Virksomheden er under konkurs
                  </Tag>
                </div>
              )}
              <ul tw="list-none p-0 m-0 mt-4 space-y-4">
                <StyledListItem>
                  <span>Virksomhed</span>
                  <span>{suggestedCvrInfo.name}</span>
                </StyledListItem>
                <StyledListItem>
                  <span>Startdato</span>
                  <span>{suggestedCvrInfo.startdate}</span>
                </StyledListItem>
                <StyledListItem>
                  <span>Adresse</span>
                  <span>{suggestedCvrInfo.address}</span>
                </StyledListItem>
                <StyledListItem>
                  <span>Postnr. og by</span>
                  <span>
                    {suggestedCvrInfo.zipcode} {suggestedCvrInfo.city}
                  </span>
                </StyledListItem>
                {suggestedCvrInfo.phone && (
                  <StyledListItem>
                    <span>Hovedtelefon</span>
                    <span>{suggestedCvrInfo.phone}</span>
                  </StyledListItem>
                )}
                {suggestedCvrInfo.email && (
                  <StyledListItem>
                    <span>Email</span>
                    <span>{suggestedCvrInfo.email}</span>
                  </StyledListItem>
                )}
                <StyledListItem>
                  <span>Antal medarbejdere</span>
                  <span>{suggestedCvrInfo.employees}</span>
                </StyledListItem>
                <StyledListItem>
                  <span>Reklamebeskyttet?</span>
                  <span>{suggestedCvrInfo.protected === 1 ? "Ja" : "Nej"}</span>
                </StyledListItem>
              </ul>
            </div>
          )}
          <ButtonGroup
            sticky="bottom"
            tw="px-8 py-4 m-0 bg-white lg:(px-0 mt-8)"
          >
            <Button type="button" variant="cancel" onClick={() => navigate(-1)}>
              Annullér
            </Button>
            <Button type="submit" variant="primary">
              {saveButtonText}
            </Button>
          </ButtonGroup>
        </div>

        {showSettings && (
          <div className="group">
            <FeatureComponent allowedUserRoles={[UserTypeEnum.CMSADMIN]}>
              <h3 tw="m-0 mb-8">{settingsTitle}</h3>
              <FormField label="Kontotype" tw="z-40 relative">
                <Select
                  tw="pt-6"
                  placeholder="Vælg kontotype"
                  options={accountType}
                  onChange={(opt) => updateModel("description", opt.value)}
                  defaultValue={accountType.find(
                    (opt) => opt.value === currentAccount.description
                  )}
                />
              </FormField>
              <FormField label="PowerBI" tw="z-30 relative">
                <Select
                  tw="pt-6"
                  isMulti
                  placeholder="Vælg PowerBI features"
                  options={accountFeatureOptions}
                  onChange={(opt) => {
                    return updateModel(
                      "features",
                      Object.assign({}, ...opt.map((x) => x.value))
                    )
                  }}
                  defaultValue={defaultAccountFeatures}
                />
              </FormField>
              <FormField label="Tilkøb" tw="z-20 relative">
                <Select
                  tw="pt-6"
                  isMulti
                  placeholder="Vælg tilkøbte funktioner"
                  options={accountFunctionsOptions}
                  onChange={(opt) => {
                    return updateModel(
                      "functions",
                      Object.assign({}, ...opt.map((x) => x.value))
                    )
                  }}
                  defaultValue={defaultAccountFunctions}
                />
              </FormField>
              {currentAccount.functions?.hasOwnProperty("MESSENGER") && (
                <FormField
                  validationKey="msisdn"
                  label="Gateway API afsendernummer"
                >
                  <ValidationInput
                    id="msisdn"
                    type="text"
                    placeholder="Inkl. landekode, f.eks. '45xxxxxxxx'"
                    onChange={(e) => updateModel("msisdn", e.target.value)}
                    defaultValue={currentAccount.msisdn}
                  />
                </FormField>
              )}
              <FormField tw="border-0">
                <Checkbox
                  appearance="toggle"
                  checked={currentAccount.eRegnskabEnabled === 1}
                  onChange={(checked) =>
                    updateModel("eRegnskabEnabled", checked ? 1 : 0)
                  }
                >
                  Integration til eRegnskab?
                </Checkbox>
              </FormField>
              {currentAccount.eRegnskabEnabled === 1 && (
                <FormField
                  validationKey="eregnskabApiKey"
                  label="eRegnskab API-nøgle"
                >
                  <ValidationInput
                    id="eregnskabApiKey"
                    type="text"
                    placeholder="API Nøgle til eRegnskab"
                    onChange={(e) =>
                      updateModel("eRegnskabAPIkey", e.target.value)
                    }
                    defaultValue={currentAccount.eRegnskabAPIkey}
                  />
                </FormField>
              )}
              <h3 tw="mt-8 mb-8">Kort</h3>
              <FormField validationKey="latitude" label="Kort breddegrad">
                <ValidationInput
                  id="latitude"
                  type="number"
                  step="0.0000001"
                  placeholder="Indtast kort breddegrad"
                  onChange={(e) => updateModel("mapLatitude", e.target.value)}
                  defaultValue={currentAccount.mapLatitude}
                />
              </FormField>
              <FormField validationKey="longitude" label="Kort længdegrad">
                <ValidationInput
                  id="longitude"
                  type="number"
                  step="0.0000001"
                  placeholder="Indtast kort længdegrad"
                  onChange={(e) => updateModel("mapLongitude", e.target.value)}
                  defaultValue={currentAccount.mapLongitude}
                />
              </FormField>
              <FormField validationKey="zoomLevel" label="Kort zoomniveau">
                <ValidationInput
                  id="zoomLevel"
                  type="number"
                  step="1"
                  placeholder="Indtast kort zoom niveau"
                  onChange={(e) =>
                    updateModel("defaultMapZoom", e.target.value)
                  }
                  defaultValue={currentAccount.defaultMapZoom}
                />
              </FormField>
            </FeatureComponent>
          </div>
        )}
        {currentAccount?.description === ApiAccountTypeEnum.Installer && (
          <div className="group">
            <FeatureComponent allowedUserRoles={[UserTypeEnum.CMSADMIN]}>
              <h3 tw="m-0 mb-8">Montørindstillinger</h3>
              <FormField label="Særlige funktioner" tw="z-20 relative">
                <Select
                  tw="pt-6"
                  isMulti
                  placeholder="Vælg montørfunktioner"
                  options={installerAccountFeatureOptions}
                  onChange={(opt) => {
                    console.log(opt)
                    return updateModel(
                      "installerAccountFeatures",
                      opt.map((x) => x.value)
                    )
                  }}
                  defaultValue={defaultInstallerAccountFeatures}
                />
              </FormField>
            </FeatureComponent>
          </div>
        )}
      </StyledFormGrid>
    </StyledForm>
  )
}
