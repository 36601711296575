import { AccountsContext } from "app/Account/context"
import { ApiAccountTypeEnum } from "app/Account/types"
import { DevicesContext } from "app/Devices/context"
import { groupBy, orderBy } from "lodash-es"
import { useContext, useMemo } from "react"
import tw from "twin.macro"

enum ColorThemeEnum {
  Green = "Green",
  Yellow = "Yellow",
  Blue = "Blue",
  Brown = "Brown",
  Red = "Red",
  Neutral = "Neutral",
}

const chartColorTheme = {
  [ColorThemeEnum.Green]: tw`fill-brand-green-50 stroke-brand-green-50`,
  [ColorThemeEnum.Yellow]: tw`fill-brand-yellow-100 stroke-brand-yellow-100`,
  [ColorThemeEnum.Blue]: tw`fill-brand-blue-200 stroke-brand-blue-200`,
  [ColorThemeEnum.Brown]: tw`fill-brand-brown-400 stroke-brand-brown-400`,
  [ColorThemeEnum.Red]: tw`fill-brand-red-400 stroke-brand-red-400`,
  [ColorThemeEnum.Neutral]: tw`fill-brand-gray-light stroke-brand-gray-light`,
}

const genericColorTheme = {
  0: tw`fill-red-500 stroke-red-500 text-red-500`,
  1: tw`fill-orange-700 stroke-orange-700 text-orange-700`,
  2: tw`fill-amber-300 stroke-amber-300 text-amber-300`,
  3: tw`fill-yellow-500 stroke-yellow-500 text-yellow-500`,
  4: tw`fill-lime-500 stroke-lime-500 text-lime-500`,
  5: tw`fill-green-500 stroke-green-500 text-green-500`,
  6: tw`fill-emerald-200 stroke-emerald-200 text-emerald-200`,
  7: tw`fill-teal-400 stroke-teal-400 text-teal-400`,
  8: tw`fill-cyan-700 stroke-cyan-700 text-cyan-700`,
  9: tw`fill-sky-300 stroke-sky-300 text-sky-300`,
  10: tw`fill-blue-500 stroke-blue-500 text-blue-500`,
  11: tw`fill-indigo-200 stroke-indigo-200 text-indigo-200`,
  12: tw`fill-violet-800 stroke-violet-800 text-violet-800`,
  13: tw`fill-purple-500 stroke-purple-500 text-purple-500`,
  14: tw`fill-fuchsia-700 stroke-fuchsia-700 text-fuchsia-700`,
  15: tw`fill-pink-300 stroke-pink-300 text-pink-300`,
  16: tw`fill-rose-400 stroke-rose-400 text-rose-400`,
}

const legendColorTheme = {
  [ColorThemeEnum.Green]: tw`text-brand-green-50`,
  [ColorThemeEnum.Yellow]: tw`text-brand-yellow-100`,
  [ColorThemeEnum.Blue]: tw`text-brand-blue-200`,
  [ColorThemeEnum.Brown]: tw`text-brand-brown-400`,
  [ColorThemeEnum.Red]: tw`text-brand-red-400`,
  [ColorThemeEnum.Neutral]: tw`text-brand-gray-light`,
}

export const useDataInsight = () => {
  const {
    state: { accounts },
  } = useContext(AccountsContext)
  const {
    state: { devices },
  } = useContext(DevicesContext)

  const accountTypes = useMemo(() => {
    return [
      {
        id: ApiAccountTypeEnum.Client,
        label: ApiAccountTypeEnum.Client,
        count: accounts.filter(
          (x) => x.description === ApiAccountTypeEnum.Client
        ).length,
        total: accounts.length,
        color: ColorThemeEnum.Green,
      },
      {
        id: ApiAccountTypeEnum.Pilot,
        label: ApiAccountTypeEnum.Pilot,
        count: accounts.filter(
          (x) => x.description === ApiAccountTypeEnum.Pilot
        ).length,
        total: accounts.length,
        color: ColorThemeEnum.Yellow,
      },
      {
        id: ApiAccountTypeEnum.Retailer,
        label: ApiAccountTypeEnum.Retailer,
        count: accounts.filter(
          (x) => x.description === ApiAccountTypeEnum.Retailer
        ).length,
        total: accounts.length,
        color: ColorThemeEnum.Blue,
      },
      {
        id: "other",
        label: "Øvrige",
        count: accounts.filter(
          (x) =>
            ![
              ApiAccountTypeEnum.Retailer,
              ApiAccountTypeEnum.Pilot,
              ApiAccountTypeEnum.Client,
            ].includes(x.description)
        ).length,
        total: accounts.length,
        color: ColorThemeEnum.Brown,
      },
    ]
  }, [accounts])

  const deviceTypes = useMemo(() => {
    const data: any[] = []
    const groupedDevices = groupBy(devices, "deviceTypeName")
    Object.entries(groupedDevices).forEach(([key, value]) => {
      data.push({
        id: key.toLowerCase().replace(" ", "_"),
        label: key,
        count: value.length,
        total: devices.length,
      })
    })
    return data.filter((x) => x.count >= 200)
  }, [devices])

  const devicesByAccount = useMemo(() => {
    const data: any[] = []
    const groupedDevices = groupBy(devices, "account")
    Object.entries(groupedDevices).forEach(([key, value]) => {
      data.push({
        id: key.toLowerCase().replace(" ", "_"),
        label: key === "" ? "Ingen konto" : key,
        count: value.length,
        total: devices.length,
      })
    })
    return data.filter((x) => x.count >= 150)
  }, [devices])

  const generateDonutDataSeries = (data) => {
    const series = data.map((dataItem, i) => ({
      id: dataItem.id,
      angle: ((dataItem.count / dataItem.total) * 100).toFixed(2),
      style: chartColorTheme[dataItem.color] ?? genericColorTheme[i],
      label: `${((dataItem.count / dataItem.total) * 100).toFixed(2)}%`,
    }))
    const legend = data.map((dataItem, i) => ({
      id: dataItem.id,
      style: legendColorTheme[dataItem.color] ?? genericColorTheme[i],
      label: `${dataItem.label}`,
      data: `${((dataItem.count / dataItem.total) * 100).toFixed(2)}%`,
      count: dataItem.count,
    }))
    return {
      series,
      legend: orderBy(legend, "count", "desc"),
    }
  }

  return {
    accountTypes,
    deviceTypes,
    devicesByAccount,
    generateDonutDataSeries,
  }
}
