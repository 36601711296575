import React, { useEffect, useMemo, useState } from "react"
import tw from "twin.macro"
import { usePagination } from "./hooks"
import styled from "styled-components"
import { Select } from "lib/Select"
import { Button, Icon, Responsive } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import slice from "lodash-es/slice"

type PaginationProps = {
  pages: any[]
  defaultChunkSize?: number
  onSetChunkSize: (size: number | 0) => void
}

const PaginationItem = styled.li<{ isCurrent: boolean }>`
  ${tw`w-12 h-12 relative rounded-full leading-none flex items-center justify-center`}
  ${(props) => (props.isCurrent ? tw`bg-brand-gray-brand` : tw`cursor-pointer`)}
`

const options = [
  { label: 50, value: 50 },
  { label: 100, value: 100 },
  { label: 200, value: 200 },
  { label: 300, value: 300 },
  { label: "Alle", value: 0 },
]

export const Pagination: React.FC<PaginationProps> = ({
  pages,
  defaultChunkSize = 50,
  onSetChunkSize,
  ...props
}) => {
  const { setCurrentPage, currentPage } = usePagination(1)
  const [selectedValue, setSelectedValue] = useState(
    options.find((x) => x.value === defaultChunkSize)
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [pages.length])

  const onChangeHandler = (opt) => {
    if (onSetChunkSize) onSetChunkSize(opt.value)
    setSelectedValue(opt)
  }

  const [prevPage, nextPage] = useMemo(() => {
    if (currentPage) {
      const next = currentPage + 1 >= pages.length + 1 ? null : currentPage + 1
      const prev = currentPage === 1 ? null : currentPage - 1
      return [prev, next]
    }
    return [null, null]
  }, [currentPage, pages])

  return (
    <nav tw="flex items-center justify-between relative z-40">
      <ul tw="m-0 p-0 list-none flex space-x-2">
        <Button
          type="button"
          variant="icon"
          disabled={prevPage === null}
          invert
          onClick={() => setCurrentPage(prevPage)}
        >
          <Icon icon="chevron-left" size={IconSizeEnum.SM} />
        </Button>
        <Responsive
          phone={
            <Select
              tw="bg-brand-gray-brand"
              placeholder="Vælg side"
              menuPlacement="auto"
              onChange={(opt) => setCurrentPage(opt.value)}
              value={{ label: currentPage, value: currentPage }}
              options={pages.map((x, i) => ({
                label: `${i + 1}`,
                value: i + 1,
              }))}
            />
          }
          tabletLandscape={
            <>
              {pages.map((x, i) => (
                <PaginationItem
                  key={`page_${i}`}
                  onClick={() => setCurrentPage(i + 1)}
                  isCurrent={currentPage === i + 1}
                >
                  <span tw="absolute">{i + 1}</span>
                </PaginationItem>
              ))}
            </>
          }
        />
        <Button
          type="button"
          variant="icon"
          invert
          disabled={nextPage === null}
          onClick={() => setCurrentPage(nextPage)}
        >
          <Icon icon="chevron-right" size={IconSizeEnum.SM} />
        </Button>
      </ul>
      <div tw="flex items-center">
        <span tw="mr-4">Resultater pr. side</span>
        <Select
          tw="bg-brand-gray-brand"
          placeholder="Resultater pr. side"
          onChange={onChangeHandler}
          value={selectedValue}
          menuPlacement="auto"
          options={options}
        />
      </div>
    </nav>
  )
}
