import { FeatureEnum } from "app/FeatureComponent/types"
import { StringDictionary } from "lib/global.types"

// Account types
export type BasicApiAccount = {
  id: string
  name: string
  contact: string // A name
  contact_phone: string // Phone number of the primary contact
  address: string
  zip: string
  city: string
  phone: string
  created: number
}

export enum ApiAccountTypeEnum {
  Demo = "Demokonto",
  Client = "Kundekonto",
  Retailer = "Forhandler",
  Installer = "Montør",
  Pilot = "Pilot",
  Churned = "Opsagt",
  Internal = "Medarbejder konto",
}

export enum AccountFeatureEnum {
  Reports = "Rapporter",
  Dashboard = "Dashboard",
}

export enum InstallerAccountFeatureEnum {
  SkipCANDataDocumentation = "SkipCANDataDocumentation",
}

export enum AccountFunctionsEnum {
  Drivers = "Fører ID",
  Messenger = "Messenger",
}

export type BasicAccount = {
  id: string
  name: string
  contact: string // A name
  contactPhone: string // Phone number of the primary contact
  address: string
  zip: string
  city: string
  phone: string
  email?: string
  created: number
  description: ApiAccountTypeEnum
  features: StringDictionary<string>
  functions: StringDictionary<string>
  assignedAccounts?: { account: string; accountID: string }[]
}

// If OptionalAccountData is filled out, we'll create an entity in Firestore,
// to enable the ability to create a 'layer' on top of the core data from the API,
// to enable various integrations (such as fenerum etc.)
type OptionalAccountData = {
  vatNumber?: number
  country?: string
  msisdn?: string
  mapLatitude?: number
  mapLongitude?: number
  defaultMapZoom?: number
}

// This account type comes from https://staging.webapi.clevertrack.dk/Help/Api/GET-api-Company
export type ClientAccount = BasicAccount &
  OptionalAccountData & {
    unitCount: number
    vehicleCount: number
    probeCount: number
    eRegnskabEnabled: number // 1 or 0
    eRegnskabAPIkey: string
  }

export type ClientApiAccount = BasicApiAccount & {
  unitCount: number
  vehicleCount: number
  probeCount: number
  eRegnskabEnabled: number // 1 or 0
  eRegnskabAPIkey: string
}

// This account type is maintained on Firestore, and shares _some_ properties with ClientAccounts
export type PartnerAccount = BasicAccount &
  OptionalAccountData & {
    accountIDs: number[] // Accounts assigned to this partner
    hubFeatures: FeatureEnum[] // These features are available for users belonging to a PartnerAccount, with the flag "isAccountAdmin" enabled.
    installerAccountFeatures?: InstallerAccountFeatureEnum[]
  }

export type Account = ClientAccount & PartnerAccount
