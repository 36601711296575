import React, { useContext, useEffect, useState } from "react"
import { Button, ButtonGroup, Loading } from "@clevertrack/shared"
import styled from "styled-components"
import "twin.macro"
import { InstallationScreenEnum } from "../types"
import { InstallationContext } from "../context"
import { UserContext } from "app/User/context"
import { AccountsContext } from "app/Account/context"
import format from "date-fns/format"
import { StyledPopoverContentContainer } from "./components"
import {
  IconIllustration,
  SecondaryIconPositionEnum,
} from "app/Visuals/IconIllustration"
import cogoToast from "@clevertrackdk/cogo-toast"
import { InstallationActions, InstallationTypes } from "../actions"
import { createVehicle, deleteVehicle, patchVehicle } from "services/vehicles"
import { DeviceStockStatusEnum } from "app/Devices/types"
import { useFirestoreDevices } from "services/firestore/devices"
import { DeviceAuditLogEvent } from "app/AuditLog/audit.types"
import { useInstallation } from "../hooks"
import { useBackgroundDataFetch } from "app/Dashboard/hooks"
import { DevicesContext } from "app/Devices/context"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"

const StyledUninstallPopoverContentContainer = styled(
  StyledPopoverContentContainer
)`
  grid-template-rows: 1fr auto;
`

type UninstallType = {
  onSelectScreen: (screen: InstallationScreenEnum | null) => void
}

export const Uninstall: React.FC<UninstallType> = ({
  onSelectScreen,
  ...props
}) => {
  const {
    state: { device, installationSteps, account },
    dispatch,
  } = useContext(InstallationContext)
  const { dispatch: devicesDispatch } = useContext(DevicesContext)
  const {
    saveFirebaseDevice,
    deleteInstallationStepsByImei,
    newDeviceAuditLogEntry,
  } = useFirestoreDevices()
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { account: userAccount },
  } = useContext(AccountsContext)
  const [inProgress, setInProgress] = useState(false)
  const { getApiDevices } = useBackgroundDataFetch()
  const { getAccountApiVehicles } = useInstallation()

  const onUninstallDeviceHandler = async () => {
    try {
      setInProgress(true)
      const unmountDate = new Date()
      const unmountedNote = `Afmonteret af ${user?.firstName} fra ${
        userAccount?.name
      } d. ${format(unmountDate, "dd/MM yyyy")}`
      // When uninstalling a device, we must make sure to clean up properly.
      // That means deleting any images that where uploaded, as well as any
      // installation steps that have been filled out.
      // We also need to:
      // a) delete the vehicle attached to the device
      // b) create a new vehicle ID, so we can inform the client on frontend
      // about a vacant unit. The unit should have stockStatus "Stock".

      // Next, we delete the vehicle in the API...
      const deleteVehiclePromise = await deleteVehicle(device?.id)

      if (deleteVehiclePromise.result === "OK") {
        // ...create a new vehicle in the API...
        const availableUnitVehiclePromise = await createVehicle(
          {
            unit_id: device?.unit_id,
            name: `Ledig enhed`,
          },
          account?.id
        )
        if (
          availableUnitVehiclePromise.result === "OK" &&
          availableUnitVehiclePromise.vehicles
        ) {
          const newVehicleData = {
            id: availableUnitVehiclePromise.vehicles[0].id,
            name: availableUnitVehiclePromise.vehicles[0].name,
            note: unmountedNote,
            installationStartedOnAccountID: null,
            imei: device?.imei?.toString(),
            stockStatus: DeviceStockStatusEnum.Stock,
            statusUpdated: +new Date(),
            account: account?.name,
            accountID: account?.id,
            availableUnit: true,
          }

          // ...update API with new description
          const patchVehiclePromise = patchVehicle(
            {
              ...availableUnitVehiclePromise.vehicles[0],
              note: unmountedNote,
            },
            availableUnitVehiclePromise.vehicles[0].id
          )

          // ...update firebase with new data...
          const firebaseDeviceUpdatePromise = saveFirebaseDevice(
            newVehicleData,
            device?.imei?.toString()
          )

          // ...remove previous installation steps from firebase...
          const firebaseInstallationStepsDeletionPromise = deleteInstallationStepsByImei(
            device?.imei?.toString()
          )

          // ...and update the audit log with the event.
          const firebaseAuditLogEntryPromise = newDeviceAuditLogEntry(
            device?.imei?.toString(),
            {
              event: DeviceAuditLogEvent.Uninstalled,
              timestamp: +unmountDate,
              user: `${user?.firstName} ${user?.lastName}`,
              userID: user?.id ?? 0,
              userAccount: userAccount?.name.toString() ?? "",
              userAccountID: +userAccount?.id ?? 0,
            }
          )

          const results = await Promise.all([
            patchVehiclePromise,
            firebaseDeviceUpdatePromise,
            firebaseInstallationStepsDeletionPromise,
            firebaseAuditLogEntryPromise,
          ])

          if (results.map((x) => x.result).some((result) => result !== "OK")) {
            cogoToast.error("Noget gik galt under afinstallering af enheden")
          } else {
            devicesDispatch(
              DevicesActions(DevicesTypes.UpdateDeviceByID, {
                device: newVehicleData,
              })
            )
            dispatch(
              InstallationActions(InstallationTypes.UpdateDeviceByID, {
                device: newVehicleData,
              })
            )
            cogoToast.success("Enheden blev korrekt afinstalleret")
            dispatch(InstallationActions(InstallationTypes.ClearDevice))
            onSelectScreen(null)
          }
        } else {
          cogoToast.error("Kunne ikke oprette en ny ledig enhed")
        }
      } else {
        cogoToast.error("Kunne ikke slettet emnet enheden er tilknyttet")
      }
      setInProgress(false)
    } catch (error) {
      cogoToast.error("Kunne ikke afinstallere enheden")
    }
  }

  useEffect(() => {
    return () => {
      setInProgress(false)
    }
  }, [])

  return (
    <StyledUninstallPopoverContentContainer>
      <div tw="text-center self-center p-16">
        <IconIllustration
          tw="my-16"
          primaryIcon="tools"
          secondaryIcon="long-arrow-down"
          secondaryIconPosition={SecondaryIconPositionEnum.BottomRight}
        />
        {!inProgress && (
          <>
            <h2 tw="mb-0">Vil du afinstallere enheden?</h2>
            <p tw="text-2xl leading-snug">
              <strong>{device?.name}</strong>
              {` bliver slettet, og enheden kan monteres igen for `}
              <strong>{account?.name}</strong>
            </p>
          </>
        )}

        {inProgress && (
          <>
            <h2 tw="mb-0">Afinstallerer enheden</h2>
            <Loading includeWrapper={false} loadingText="Vent venligst" />
          </>
        )}

        {/* <FormField label="Hvor befinder enheden sig?" tw="text-left">
          <Select
            tw="pt-4"
            placeholder="Vælg fra listen"
            options={[
              { label: "Hos montøren", value: "installer" },
              { label: `Hos ${account.name}`, value: "client" },
            ]}
          />
        </FormField> */}
      </div>
      <ButtonGroup sticky="bottom" tw="bg-white px-4">
        <Button
          type="button"
          variant="cancel"
          disabled={inProgress}
          onClick={() => onSelectScreen(InstallationScreenEnum.Tasks)}
        >
          <span tw="flex items-center">
            <span tw="text-xl font-normal">Annullér</span>
          </span>
        </Button>
        <Button
          type="button"
          variant="danger"
          disabled={inProgress}
          onClick={onUninstallDeviceHandler}
        >
          <span tw="flex items-center">
            <span tw="text-xl font-bold">Bekræft</span>
          </span>
        </Button>
        {/* <Button type="button" variant="primary">
            Start gennemgang
          </Button> */}
      </ButtonGroup>
    </StyledUninstallPopoverContentContainer>
  )
}
