export enum DeviceAuditLogEvent {
  Installed = "Installed",
  Uninstalled = "Uninstalled",
}

export type DeviceAuditLogItem = {
  id?: string
  event: DeviceAuditLogEvent
  timestamp: number
  user: string
  userAccount: string
  userID: number
  userAccountID: number
}
