import React, { useContext, useEffect, useState } from "react"
import { useParams, Router } from "@gatsbyjs/reach-router"
import "twin.macro"
import { Button, Loading } from "@clevertrack/shared"
import styled from "styled-components"
import { MainContainer } from "app/MainContainer"
import Icon, { IconSizeEnum } from "lib/Icon"
import { ConfigurationForm } from "app/Configuration/Form"
import { useDeviceConfiguration } from "app/Configuration/hooks"
import { ConfigurationContext } from "app/Configuration/context"
import { EmptyList } from "components/EmptyList"
import { DataList } from "app/DataList"
import tw from "twin.macro"
import { format } from "date-fns"
import { SearchProvider } from "app/Search/context"
import { navigate } from "gatsby"

const StyledGrid = styled.section`
  ${tw`w-full h-full`}
  display: grid;
  grid-template-columns: 30rem 1fr;
`

export const DeviceConfiguration: React.FC = ({ ...props }) => {
  const { configID } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const {
    state: { configuration, configurationList },
  } = useContext(ConfigurationContext)
  const {
    getConfigs,
    getAvailableDisplayKeys,
    createNewConfig,
    selectConfig,
    saveConfig,
  } = useDeviceConfiguration()

  useEffect(() => {
    // On mount...
    const loadConfigs = async () => {
      setIsLoading(true)
      const result = await getConfigs()
      if (result === "OK") setIsLoading(false)
    }

    const loadDisplayKeys = async () => {
      await getAvailableDisplayKeys()
    }

    loadConfigs()
    loadDisplayKeys()
  }, [])

  const onCreateConfiguration = async () => {
    const newConfig = await createNewConfig()
    if (newConfig) {
      navigate(`/app/configuration/${newConfig.id}`)
    }
  }

  return (
    <MainContainer
      header={
        <>
          <h2>Konfigurationer</h2>
          <Button
            type="button"
            variant="primary"
            icon="right"
            size="sm"
            tw="ml-8"
            onClick={onCreateConfiguration}
          >
            <span>Ny konfiguration</span>
            <Icon icon="plus" size={IconSizeEnum.SM} />
          </Button>
        </>
      }
      tw="relative w-full h-full"
    >
      {isLoading ? (
        <Loading loadingText="Indlæser konfigurationer..." />
      ) : (
        <StyledGrid>
          <div tw="border border-0 border-r border-solid border-brand-gray-light">
            {configurationList.length === 0 ? (
              <EmptyList
                content="Endnu ingen konfigurationer"
                icon="box-open"
                action={
                  <Button
                    type="button"
                    variant="primary"
                    icon="right"
                    size="sm"
                    onClick={onCreateConfiguration}
                  >
                    <span>Opret din første konfiguration</span>
                    <Icon icon="plus" size={IconSizeEnum.SM} />
                  </Button>
                }
              />
            ) : (
              <SearchProvider>
                <DataList
                  includeSearch
                  searchKeys={["name"]}
                  searchPlaceHolder="Find konfiguration"
                  selectedItem={configuration}
                  items={configurationList}
                  onItemSelect={(item) => selectConfig(item)}
                  titleKey="name"
                  secondaryDescriptionKey="created"
                  secondaryDescriptionKeyFormatFnc={(input) => {
                    return input
                      ? format(
                          new Date(input),
                          "'Oprettet d. ' dd-MM-yyyy 'kl.' HH:mm"
                        )
                      : null
                  }}
                  tertiaryDescriptionKey="configurationFile"
                  tertiaryDescriptionKeyFormatFnc={(input) =>
                    input?.label ?? null
                  }
                  tertiaryDescriptionStyling={tw`text-lg truncate text-ellipsis whitespace-nowrap overflow-hidden`}
                />
              </SearchProvider>
            )}
          </div>
          <div tw="bg-brand-gray-brand w-full h-full">
            {configuration ? (
              <ConfigurationForm onSave={saveConfig} />
            ) : (
              <EmptyList
                icon="cog"
                content="Vælg en konfiguration på listen"
                action={<></>}
              />
            )}
          </div>
        </StyledGrid>
      )}
    </MainContainer>
  )
}
