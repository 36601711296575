import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import "twin.macro"
import {
  FlowHeader,
  ScreenHeader,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepOptionalDataProps,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { useFormData } from "hooks/useFormData"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { FormFieldToggle } from "components/FormFieldToggle"
import { InstallationContext } from "../context"
import { useFileUpload } from "app/FileUpload/hooks"
import { PhotoUpload } from "./components/PhotoUpload"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import { useInstallation } from "../hooks"
import { getInstallationStepState } from "./helper"
import debounce from "lodash-es/debounce"
import isEqual from "lodash-es/isEqual"
import { Select } from "lib/Select"
import { getLicensePlateInfo } from "services/integrations/synsbasen"
import orderBy from "lodash-es/orderBy"
import { useFirestoreDevices } from "services/firestore/devices"
import { toggleItemsInEnumArray } from "utils/array"
import uniq from "lodash-es/uniq"

export const DeviceInformation: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen, vehicleGroups },
    dispatch,
  } = useContext(InstallationContext)

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceInformationModel
  >(stepData?.data)
  const [optionalDataProps, setOptionalDataProps] = useState<
    InstallationStepOptionalDataProps[]
  >(stepData?.optionalDataProps ?? [])
  const { deviceTypeCategory, setCurrentScreen } = useInstallation()
  const { saveFirebaseDevice } = useFirestoreDevices()
  const {
    onUploadDocumentHandler,
    uploadMap,
    removeItemFromUploadMapByKey,
  } = useFileUpload(`${account?.id}/${device?.imei}`, account?.id)

  const availableGroups = useMemo(() => {
    return orderBy(vehicleGroups, "name").map((x) => ({
      label: x.name,
      value: x.id,
    }))
  }, [vehicleGroups])

  const onSelectGroupHandler = (opt) => {
    updateModel("vehicleGroup", opt.length > 0 ? opt : null)
  }

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.DeviceInformation, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.DeviceInformation &&
      stepData &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData: InstallationStep = {
        ...stepData,
        data: currentModel,
        stepState: getInstallationStepState(currentModel, optionalDataProps),
        optionalDataProps: optionalDataProps,
      }

      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, stepData, autoSaveFunc, currentScreen])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(currentModel, optionalDataProps),
    }

    const apiSave: Promise<any>[] = []

    apiSave.push(
      saveFirebaseDevice(
        {
          patchVehiclePayload: {
            name: currentModel.vehicleName,
            note: currentModel.vehicleDescription ?? "",
            customer_id: device?.customer_id ?? 0,
            subscription_type: device?.subscription_type ?? 0,
            subscription_addon: device?.subscription_addon ?? 0,
            aempid: device?.aempid ?? "",
          },
          deviceCalibrationPayload: {
            name: currentModel.vehicleName,
            note: currentModel.vehicleDescription ?? "",
          },
        },
        device.imei
      )
    )

    if (currentModel?.vehicleGroup) {
      apiSave.push(
        saveFirebaseDevice(
          {
            vehicleToGroupPayload: {
              groups: currentModel?.vehicleGroup.map((x) => x.value),
            },
          },
          device.imei
        )
      )
    }

    onSave(
      InstallationScreenEnum.DeviceInformation,
      newData,
      Promise.all(apiSave)
    )
  }

  const onAcceptFilesHandler = async (files, filename, key) => {
    await onUploadDocumentHandler(files, filename, key)
  }

  const onVertexAIResponseHandler = async (result) => {
    const jsonString = result.replace("```json", "").replace("```", "")
    const json = JSON.parse(jsonString)

    if (json.make) {
      updateModel("vehicleBrand", json.make)
    }

    if (json.model) {
      updateModel("vehicleModel", json.model)
    }

    if (json.year) {
      updateModel("vehicleYear", json.year)
    }

    if (json.VIN) {
      updateModel("vehicleFrameNumber", json.VIN)
    }

    if (json.vehicleRegistrationNumber) {
      const reg = json.vehicleRegistrationNumber.replaceAll(" ", "")
      const vehicleInfoReq = await getLicensePlateInfo(reg)
      if (vehicleInfoReq.status === 200 && vehicleInfoReq.data.data) {
        const { brand, model, vin, model_year } = vehicleInfoReq.data.data
        updateModel("vehicleRegistrationNumber", reg)
        updateModel("vehicleYear", model_year)
        updateModel("vehicleFrameNumber", vin)
        updateModel("vehicleModel", model)
        updateModel("vehicleBrand", brand)
      }
    }
  }

  useMemo(() => {
    Object.values(uploadMap).map((value) => {
      if (value.key) {
        updateModel(value.key, value.downloadURL)
        updateModel(`${value.key}Path`, value.path)
      }
    })
  }, [uploadMap])

  useMemo(() => {
    const str = [currentModel?.vehicleBrand, currentModel?.vehicleModel]
    if (currentModel?.vehicleNumber) {
      if (currentModel?.vehicleBrand || currentModel?.vehicleModel) {
        str.unshift(`-`)
      }
      str.unshift(`${currentModel.vehicleNumber}`)
    }
    if (str.filter(Boolean).length > 0)
      updateModel("vehicleName", str.filter(Boolean).join(" "))
  }, [
    currentModel?.vehicleBrand,
    currentModel?.vehicleModel,
    currentModel?.vehicleNumber,
  ])

  useMemo(() => {
    const str = [
      currentModel?.vehicleFrameNumber
        ? `Stelnr: ${currentModel.vehicleFrameNumber}`
        : null,
      currentModel?.vehicleYear ? `Årg: ${currentModel?.vehicleYear}` : null,
      ,
    ]
    if (str.filter(Boolean).length > 0)
      updateModel("vehicleDescription", str.filter(Boolean).join(" "))
  }, [currentModel?.vehicleFrameNumber, currentModel?.vehicleYear])

  const [
    machineNumberToggled,
    licensePlateToggled,
    typePlateToggled,
  ] = useMemo(() => {
    const toggledFields = [true, true, true]
    if (optionalDataProps.includes("vehicleNumber")) {
      toggledFields[0] = false
    }

    if (
      optionalDataProps.includes("vehicleRegistrationNumber") &&
      optionalDataProps.includes("vehicleLicensePlatePhoto") &&
      optionalDataProps.includes("vehicleLicensePlatePhotoPath")
    ) {
      toggledFields[1] = false
    }

    if (
      optionalDataProps.includes("vehicleTypePlatePhoto") &&
      optionalDataProps.includes("vehicleTypePlatePhotoPath")
    ) {
      toggledFields[2] = false
    }

    return toggledFields
  }, [])

  return (
    <StyledPopoverContentContainer>
      <FlowHeader name={currentModel?.legacyVehicleName} />
      <ScreenHeader>Enhedsinformation</ScreenHeader>
      <Form onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16 relative z-50">
          <FormFieldToggle
            toggleQuestion={`Har enheden ikke et ${
              [
                DeviceTypeCategoryEnum.Beacon,
                DeviceTypeCategoryEnum.GPSTrackerWithBattery,
              ].includes(deviceTypeCategory)
                ? `materielnummer`
                : `maskin-/køretøjsnummer`
            }?`}
            untoggleText="Fjern feltet"
            toggleText={`Tilføj ${
              [
                DeviceTypeCategoryEnum.Beacon,
                DeviceTypeCategoryEnum.GPSTrackerWithBattery,
              ].includes(deviceTypeCategory)
                ? `materielnummer`
                : `maskin-/køretøjsnummer`
            } +`}
            onDisable={() => {
              const { vehicleNumber, ...newModel } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([...prev, "vehicleNumber"])
              })
            }}
            onEnable={() => {
              updateModel("vehicleNumber", stepData?.data?.vehicleNumber ?? "")
              setOptionalDataProps((prev) => {
                return prev.filter((x) => x !== "vehicleNumber")
              })
            }}
            defaultToggled={machineNumberToggled}
          >
            <FormField
              label={
                [
                  DeviceTypeCategoryEnum.Beacon,
                  DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                ].includes(deviceTypeCategory)
                  ? `Materielnummer`
                  : `Maskin-/køretøjsnummer`
              }
            >
              <ValidationInput
                value={currentModel.vehicleNumber}
                placeholder={
                  [
                    DeviceTypeCategoryEnum.Beacon,
                    DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                  ].includes(deviceTypeCategory)
                    ? `Indtast materielnummer`
                    : `Indtast maskin- eller køretøjsnummer`
                }
                onChange={(e) => updateModel("vehicleNumber", e.target.value)}
              />
            </FormField>
          </FormFieldToggle>
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <FormFieldToggle
              toggleQuestion="Er enheden ikke indregistreret?"
              untoggleText="Fjern feltet"
              toggleText="Tilføj registreringsnummer +"
              onDisable={() => {
                const {
                  vehicleRegistrationNumber,
                  vehicleLicensePlatePhoto,
                  vehicleLicensePlatePhotoPath,
                  ...newModel
                } = currentModel
                setCurrentModel(newModel)
                setOptionalDataProps((prev) => {
                  return uniq([
                    ...prev,
                    "vehicleRegistrationNumber",
                    "vehicleLicensePlatePhoto",
                    "vehicleLicensePlatePhotoPath",
                  ])
                })
              }}
              onEnable={() => {
                updateModel(
                  "vehicleRegistrationNumber",
                  stepData?.data?.vehicleRegistrationNumber ?? ""
                )
                updateModel(
                  "vehicleLicensePlatePhoto",
                  stepData?.data?.vehicleLicensePlatePhoto ?? ""
                )
                updateModel(
                  "vehicleLicensePlatePhotoPath",
                  stepData?.data?.vehicleLicensePlatePhotoPath ?? ""
                )
                setOptionalDataProps((prev) => {
                  return prev.filter(
                    (x) =>
                      ![
                        "vehicleRegistrationNumber",
                        "vehicleLicensePlatePhoto",
                        "vehicleLicensePlatePhotoPath",
                      ].some((key) => key === x)
                  )
                })
              }}
              defaultToggled={licensePlateToggled}
            >
              <h4>Billede af reg. nr.</h4>
              <PhotoUpload
                photoPropertyKey="vehicleLicensePlatePhoto"
                photoPropertyValue={currentModel?.vehicleLicensePlatePhoto}
                photoFilename="license-plate.jpeg"
                photoPath={currentModel?.vehicleLicensePlatePhotoPath}
                photoPathPropertyKey="vehicleLicensePlatePhotoPath"
                uploadLabel=""
                uploadDescription="Tydeligt billede af nummerplade og synligt køretøj"
                onUpdateModel={updateModel}
                onDeletePhoto={() =>
                  removeItemFromUploadMapByKey("vehicleLicensePlatePhoto")
                }
                onAcceptFilesHandler={onAcceptFilesHandler}
                // onVertexAIResponse={onVertexAIResponseHandler}
                // vertexPrompt="Return the license plate number of this image as JSON. Name the property 'vehicleRegistrationNumber'"
              />
              <FormField label="Registreringsnummer" tw="mt-8">
                <ValidationInput
                  value={currentModel.vehicleRegistrationNumber}
                  placeholder="Indtast registreringsnummer"
                  onChange={(e) =>
                    updateModel(
                      "vehicleRegistrationNumber",
                      e.target.value.toUpperCase()
                    )
                  }
                />
              </FormField>
            </FormFieldToggle>
          )}
          <FormFieldToggle
            toggleQuestion="Hvis enheden er indregistreret"
            untoggleText="kan du fjerne feltet"
            toggleText="Tilføj typeplade +"
            onDisable={() => {
              const {
                vehicleTypePlatePhoto,
                vehicleTypePlatePhotoPath,
                ...newModel
              } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([
                  ...prev,
                  "vehicleTypePlatePhoto",
                  "vehicleTypePlatePhotoPath",
                ])
              })
            }}
            onEnable={() => {
              updateModel(
                "vehicleTypePlatePhoto",
                stepData?.data?.vehicleTypePlatePhoto ?? ""
              )
              updateModel(
                "vehicleTypePlatePhotoPath",
                stepData?.data?.vehicleTypePlatePhotoPath ?? ""
              )
              setOptionalDataProps((prev) => {
                return prev.filter(
                  (x) =>
                    ![
                      "vehicleTypePlatePhoto",
                      "vehicleTypePlatePhotoPath",
                    ].some((key) => key === x)
                )
              })
            }}
            defaultToggled={typePlateToggled}
          >
            <h4>Billede af typeplade</h4>
            <PhotoUpload
              photoPropertyKey="vehicleTypePlatePhoto"
              photoPropertyValue={currentModel?.vehicleTypePlatePhoto}
              photoFilename="typeplate-with-vin.jpeg"
              photoPath={currentModel?.vehicleTypePlatePhotoPath}
              photoPathPropertyKey="vehicleTypePlatePhotoPath"
              uploadLabel=""
              uploadDescription="Aftør for evt. snavs så al teksten er tydelig"
              onUpdateModel={updateModel}
              onDeletePhoto={() =>
                removeItemFromUploadMapByKey("vehicleTypePlatePhoto")
              }
              onAcceptFilesHandler={onAcceptFilesHandler}
              onVertexAIResponse={onVertexAIResponseHandler}
              vertexPrompt="Return make, model, year and VIN in JSON from this image. The first letter of the 'make' value should be in uppercase."
            />
          </FormFieldToggle>

          <FormField label="Mærke (aflæses af AI)">
            <ValidationInput
              value={currentModel.vehicleBrand}
              placeholder="Indtast mærke"
              onChange={(e) => updateModel("vehicleBrand", e.target.value)}
            />
          </FormField>
          <FormField label="Model (aflæses af AI)">
            <ValidationInput
              value={currentModel.vehicleModel}
              placeholder="Indtast model"
              onChange={(e) => updateModel("vehicleModel", e.target.value)}
            />
          </FormField>
          <FormField label="Årgang (aflæses af AI)">
            <ValidationInput
              value={currentModel.vehicleYear}
              placeholder="Indtast årgang"
              onChange={(e) => updateModel("vehicleYear", e.target.value)}
            />
          </FormField>
          {/* knacr811fps017566 */}
          <FormField label="Stelnummer (aflæses af AI)">
            <ValidationInput
              value={currentModel.vehicleFrameNumber}
              placeholder="Indtast stelnummer"
              onChange={(e) =>
                updateModel("vehicleFrameNumber", e.target.value.toUpperCase())
              }
            />
          </FormField>
          <FormField label="Særlige kendetegn (valgfri)">
            <Textarea
              defaultValue={currentModel.vehicleUniqueness}
              placeholder="Indtast særlige kendetegn eller ekstra udstyr"
              onChange={(e) => updateModel("vehicleUniqueness", e.target.value)}
            />
          </FormField>
          <FormField label="Gruppe(r)">
            <Select
              menuPlacement="top"
              placeholder="Tildel enheden til en eller flere grupper"
              options={availableGroups}
              isMulti
              onChange={onSelectGroupHandler}
              value={currentModel?.vehicleGroup}
              tw="pt-4"
            />
          </FormField>
          <FormField label="Navn (genereret)">
            <ValidationInput
              value={currentModel.vehicleName}
              placeholder="Enhedens navn"
              onChange={(e) => updateModel("vehicleName", e.target.value)}
            />
          </FormField>
          <FormField label="Beskrivelse (genereret)">
            <ValidationInput
              value={currentModel.vehicleDescription}
              placeholder="F.eks. årgang og stelnummer"
              onChange={(e) =>
                updateModel("vehicleDescription", e.target.value)
              }
            />
          </FormField>
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4 z-100">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">Tilbage</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(currentModel, optionalDataProps) !==
                InstallationStepStateEnum.Completed
            }
          >
            Gem
          </Button>
        </ButtonGroup>
      </Form>
    </StyledPopoverContentContainer>
  )
}
