import React, { useContext, useMemo } from "react"
import { UserContext } from "app/User/context"
import { UserTypeEnum, UserTypes } from "app/User/types"
import { ApiAccountTypeEnum } from "app/Account/types"
import { AccountsContext } from "app/Account/context"

type FeatureComponentType = {
  allowedUserRoles?: UserTypeEnum[]
  allowedUserFlags?: (keyof UserTypes)[]
  allowedAccountTypes?: ApiAccountTypeEnum[]
}

export const FeatureComponent: React.FC<FeatureComponentType> = ({
  allowedUserRoles = [],
  allowedUserFlags = [],
  allowedAccountTypes = [],
  children,
  ...props
}) => {
  const {
    state: { user },
  } = useContext(UserContext)
  const {
    state: { account },
  } = useContext(AccountsContext)

  const userHasAccess = useMemo(() => {
    if (user) {
      if (user?.is_cms_admin) return true
      if (allowedUserRoles.some((x) => user?.userTypes?.includes(x)))
        return true
      if (allowedUserFlags.find((key) => user[key] === 1 || user[key] === true))
        return true
      return false
    }
    return false
  }, [user, allowedUserRoles, allowedUserFlags])

  const accountHasAccess = useMemo(() => {
    if (allowedAccountTypes.length === 0) {
      return false
    } else {
      return allowedAccountTypes.some((value) =>
        account?.description.includes(value)
      )
    }
  }, [allowedAccountTypes, account, user])

  return userHasAccess || accountHasAccess ? <>{children}</> : null
}
