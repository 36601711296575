/* Action types */
import { ApiDisplayKey } from "app/Devices/types"
import { ActionMap, createActions } from "../../lib/reducer.types"
import { DeviceConfiguration } from "./types"

export enum ConfigurationTypes {
  SetConfiguration = "Configuration/SetConfiguration",
  SetConfigurations = "Configuration/SetConfigurations",
  SetAvailableDisplayKeys = "Configuration/SetAvailableDisplayKeys",
  AddConfiguration = "Configuration/AddConfiguration",
  UpdateConfigurationByID = "Configuration/UpdateConfigurationByID",
  DeleteConfigurationByID = "Configuration/DeleteConfigurationByID",
  ClearConfiguration = "Configuration/ClearConfiguration",
}

type Actions = {
  [ConfigurationTypes.SetConfiguration]: {
    configuration: DeviceConfiguration
  }
  [ConfigurationTypes.SetConfigurations]: {
    configurationList: DeviceConfiguration[]
  }
  [ConfigurationTypes.SetAvailableDisplayKeys]: {
    displayKeys: ApiDisplayKey[]
  }
  [ConfigurationTypes.AddConfiguration]: {
    configuration: DeviceConfiguration
  }
  [ConfigurationTypes.UpdateConfigurationByID]: {
    configuration: DeviceConfiguration
  }
  [ConfigurationTypes.DeleteConfigurationByID]: {
    configID: string
  }
  [ConfigurationTypes.ClearConfiguration]: null
}

export type ConfigurationActionMap = ActionMap<Actions>[keyof ActionMap<
  Actions
>]
export const ConfigurationActions = createActions<Actions>()
