import { Icon, Loading, Responsive } from "@clevertrack/shared"
import chunk from "lodash-es/chunk"
import orderBy from "lodash-es/orderBy"
import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import tw, { TwStyle } from "twin.macro"
import { CollectionFilter, filterCollection } from "utils/collection/filter"
import { ColumnMap } from "./types"
import { usePagination } from "app/Pagination/hooks"
import { Pagination } from "app/Pagination"
import { freetextSearch } from "app/Search/helper"
import { SearchContext } from "app/Search/context"
import Checkbox from "lib/Checkbox"
import { DataList, DataListType } from "app/DataList"
import { AppContext } from "context"
import uniqBy from "lodash-es/uniqBy"

const StyledDataTable = styled.section`
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
`

const StyledColumnHeader = styled.span`
  ${tw`text-lg cursor-pointer`}
`

const StyledRow = styled.div<{
  colCount: number
  colConfig: string | null
  inline?: boolean
  isDeleted?: boolean
  deletedStyle?: TwStyle
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.colConfig ? props.colConfig : `4rem repeat(${props.colCount}, 1fr)`};
  column-gap: 2rem;
  ${tw`p-4`}

  ${(props) => (props.isDeleted ? props.deletedStyle : ``)}

  &.header {
    position: sticky;
    top: ${(props) => (props.inline ? "0" : "6.4rem")};
    ${tw`bg-white`}
    z-index: 12;
  }

  &.data {
    ${tw`relative text-xl`}

    &:hover {
      ${tw`bg-brand-gray-brand`};
    }
  }
`

const StyledCell = styled.span`
  position: relative;
  z-index: 10;
`

type DataTableProps = {
  idProps?: string[]
  columns: ColumnMap
  columnConfig?: string
  filters?: CollectionFilter[]
  dataset: any[]
  onRowSelect?: (args: any) => void
  rowSelectKey?: string
  onMultiSelect?: (args: any) => void
  multiSelectIdentifierKey?: string
  inline?: boolean
  itemsPerPage?: number
  searchKeys?: string[]
  deletedProp?: string
  deletedStyle?: TwStyle
  compactSettings?: DataListType & {
    items?: any[]
  }
}

export const DataTable: React.FC<DataTableProps> = ({
  idProps = ["id"],
  columns,
  columnConfig,
  filters,
  dataset: defaultDataSet,
  onRowSelect,
  rowSelectKey,
  onMultiSelect,
  multiSelectIdentifierKey,
  inline,
  itemsPerPage = 50,
  searchKeys,
  compactSettings,
  deletedProp,
  deletedStyle,
  ...props
}) => {
  const {
    state: { query },
  } = useContext(SearchContext)
  const {
    state: { paginationPage: currentPage },
  } = useContext(AppContext)
  const [sortOrder, setSortOrder] = useState<{
    key: string
    order: "asc" | "desc"
  }>({ key: "id", order: "desc" })
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [chunkSize, setChunkSize] = useState(itemsPerPage)

  const datasetSearch = useMemo(() => {
    if (!searchKeys) return null
    return freetextSearch(defaultDataSet, {
      threshold: 0.15,
      location: 0,
      distance: 30,
      keys: searchKeys,
    })
  }, [defaultDataSet, searchKeys])

  const dataset = useMemo(() => {
    if (datasetSearch && query.length >= 2) {
      return datasetSearch.search(query).map((x) => x.item)
    }
    return defaultDataSet
  }, [query, defaultDataSet, datasetSearch])

  const [pagedDataset, results] = useMemo(() => {
    const filteredDataset = filters
      ? filterCollection(dataset, filters)
      : dataset

    const sortKeyArr = deletedProp
      ? [deletedProp, sortOrder.key]
      : [sortOrder.key]

    const sortOrderArr = deletedProp
      ? ["desc", sortOrder.order]
      : [sortOrder.order]

    const orderedDataSet = orderBy(
      filteredDataset,
      sortKeyArr,
      sortOrderArr
    ).sort((a, b) => (!a[sortOrder.key] ? 1 : -1))

    return [
      chunkSize > 0 ? chunk(orderedDataSet, chunkSize) : [orderedDataSet],
      filteredDataset.length,
    ]
  }, [dataset, sortOrder, filters, chunkSize])

  const onSortHandler = (key) => {
    if (key === sortOrder.key) {
      setSortOrder((prev) => ({
        ...prev,
        order: sortOrder.order === "asc" ? "desc" : "asc",
      }))
      return
    }

    setSortOrder({
      key,
      order: "asc",
    })
  }

  useEffect(() => {
    if (onMultiSelect) onMultiSelect(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    setSelectedRows([])
  }, [defaultDataSet])

  const renderCell = (col, item) => {
    if (col.component) {
      const { component: Component } = col
      return (
        <StyledCell key={`${item.id}_${col.key}`}>
          <Component {...item} {...col} />
        </StyledCell>
      )
    }

    const data =
      col.formatData && item[col.key]
        ? col.formatData(item[col.key])
        : item[col.key]
    return (
      <StyledCell
        key={`${item.id}_${col.key}`}
        css={
          onRowSelect && rowSelectKey === col.key
            ? tw`cursor-pointer hover:text-brand-500`
            : ``
        }
        onClick={() =>
          onRowSelect && rowSelectKey === col.key ? onRowSelect(item) : {}
        }
      >
        {data}
      </StyledCell>
    )
  }

  const renderCompact = () => {
    return (
      <>
        <div tw="mx-8 mb-4 text-xl">
          Resultater: {results}{" "}
          {selectedRows.length > 0 ? `(${selectedRows.length} valgte)` : ``}
        </div>
        {currentPage && pagedDataset[currentPage - 1] ? (
          <DataList
            items={pagedDataset[currentPage - 1]}
            onItemSelect={(item) =>
              compactSettings?.onItemSelect && !rowSelectKey
                ? compactSettings?.onItemSelect(item)
                : {}
            }
            {...compactSettings}
          />
        ) : (
          <Loading includeWrapper={false} loadingText="Indlæser data" />
        )}
        <footer
          id="dataTableFooter"
          tw="sticky z-40 bg-white py-8 px-8 border-0 border-solid border-t border-t-brand-gray-light"
          css={{ bottom: "6.4rem" }}
        >
          <Pagination
            pages={pagedDataset}
            defaultChunkSize={itemsPerPage}
            onSetChunkSize={(value) => setChunkSize(value)}
          />
        </footer>
      </>
    )
  }

  const renderFull = () => {
    return (
      <>
        <StyledDataTable {...props}>
          <div tw="m-4 text-xl">
            Resultater: {results}{" "}
            {selectedRows.length > 0 ? `(${selectedRows.length} valgte)` : ``}
          </div>
          <StyledRow
            className="header"
            colConfig={columnConfig ?? null}
            colCount={columns.length - 1}
            inline={inline}
          >
            {onMultiSelect && currentPage && (
              <StyledColumnHeader>
                <Checkbox
                  checked={
                    pagedDataset.flatMap((x) => x)?.length ===
                      selectedRows.length && dataset.length > 0
                  }
                  onChange={(checked) =>
                    setSelectedRows(
                      checked ? pagedDataset.flatMap((x) => x) : []
                    )
                  }
                />
              </StyledColumnHeader>
            )}
            {columns.map((col) => (
              <StyledColumnHeader
                key={col.key}
                onClick={() => onSortHandler(col.key)}
              >
                {col.title}
                {sortOrder.key === col.key ? (
                  <>
                    <Icon
                      icon={
                        sortOrder.order === "desc"
                          ? "chevron-up"
                          : "chevron-down"
                      }
                      tw="w-4 h-4 ml-2"
                    />
                  </>
                ) : (
                  <></>
                )}
              </StyledColumnHeader>
            ))}
          </StyledRow>
          {currentPage &&
            pagedDataset[currentPage - 1]?.map((item, i) => {
              const id = idProps.map((p) => item[p]).join("_")
              return (
                <StyledRow
                  key={id}
                  className="data"
                  isDeleted={deletedProp && item[deletedProp]}
                  deletedStyle={deletedStyle}
                  css={[onRowSelect && !rowSelectKey ? tw`cursor-pointer` : ``]}
                  onClick={() =>
                    onRowSelect && !rowSelectKey ? onRowSelect(item) : {}
                  }
                  colCount={columns.length - 1}
                  colConfig={columnConfig ?? null}
                >
                  {onMultiSelect && multiSelectIdentifierKey && (
                    <Checkbox
                      checked={selectedRows.includes(item)}
                      onChange={(checked) =>
                        setSelectedRows((prev) =>
                          checked
                            ? [...prev, item]
                            : prev.filter(
                                (x) =>
                                  x[multiSelectIdentifierKey] !==
                                  item[multiSelectIdentifierKey]
                              )
                        )
                      }
                    />
                  )}
                  {columns.map((col) => renderCell(col, item))}
                </StyledRow>
              )
            })}
        </StyledDataTable>
        <footer
          id="dataTableFooter"
          tw="sticky z-40 bg-white bottom-0 py-8 px-8 border-0 border-solid border-t border-t-brand-gray-light"
        >
          <Pagination
            pages={pagedDataset}
            defaultChunkSize={itemsPerPage}
            onSetChunkSize={(value) => setChunkSize(value)}
          />
        </footer>
      </>
    )
  }

  return (
    <Responsive
      phone={<>{renderCompact()}</>}
      tabletLandscape={<>{renderFull()}</>}
    />
  )
}
