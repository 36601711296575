import React, { useContext, useRef, useState } from "react"
import "twin.macro"
import { useUser } from "app/User/hooks"
import { MainContainer } from "app/MainContainer"
import { StyledFormGrid } from "app/Grid/FormGrid"
import { UserForm } from "app/User/Form"
import { useAccount } from "app/Account/hooks"
import { useFirestoreUser } from "services/firestore/user"
import cogoToast from "@clevertrackdk/cogo-toast"
import { UserTypes } from "app/User/types"
import { deleteUser, saveUser } from "services/users"
import { AccountsContext } from "app/Account/context"
import { Button, ButtonGroup, Dropdown, Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import { useClickAway } from "react-use"
import { navigate } from "gatsby"
import { ContextMenu } from "app/ContextMenu"
import { ApiAccountTypeEnum } from "app/Account/types"
import { generateImpersonationUrl } from "app/User/helper"

export const UserView: React.FC = ({ ...props }) => {
  const { selectedUser } = useUser()
  useAccount(selectedUser?.account_id)
  const { saveFirebaseUser, deleteFirebaseUser } = useFirestoreUser()
  const {
    state: { account },
  } = useContext(AccountsContext)
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false)

  const onSaveHandler = async (user: UserTypes) => {
    try {
      const [fbResult, apiResult] = await Promise.all([
        saveFirebaseUser(
          {
            userTypes: user.userTypes,
            feature: user.feature,
            accountID: user.account_id,
            account: account?.name,
            firstName: user.firstName,
            email: user.email,
            language: user.language,
            isAccountAdmin:
              typeof user.isAccountAdmin !== "undefined"
                ? user.isAccountAdmin
                : false,
          },
          user.id
        ),
        saveUser(user, user.id),
      ])

      if (fbResult && apiResult.result === "OK" && apiResult.user) {
        cogoToast.success("Bruger gemt!")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke gemme bruger")
    }
  }

  const onDeleteUser = async () => {
    try {
      if (selectedUser) {
        const [deleteApiResult, deleteFirebaseResult] = await Promise.all([
          deleteUser(selectedUser.id),
          deleteFirebaseUser(selectedUser.id),
        ])

        if (deleteApiResult.result === "OK" && deleteFirebaseResult === "OK") {
          cogoToast.success("Brugeren blev slettet")
          navigate(-1)
        }
      }
    } catch (error) {
      cogoToast.error("Kunne ikke slette brugeren")
    }
  }

  const onImpersonateUser = async () => {
    const url = generateImpersonationUrl(
      selectedUser,
      account?.description === ApiAccountTypeEnum.Installer
    )

    if (typeof window !== `undefined`) {
      window.open(url, "_blank")
    }
  }

  if (!selectedUser) return null

  return (
    <MainContainer
      tw="h-full"
      header={
        <>
          <h2>{[selectedUser.firstName, selectedUser.lastName].join(" ")}</h2>
          <ContextMenu>
            <ul tw="list-none p-2 px-2 m-0">
              {/* <li tw="opacity-60 block pb-2 mt-2 text-lg text-brand-gray-base truncate overflow-hidden">
                  Handlinger
                </li> */}
              <li
                tw="cursor-pointer transition-all flex px-4 py-4 items-center space-x-4 hover:(bg-brand-gray-brand)"
                onClick={onImpersonateUser}
              >
                <Icon icon="id-card-alt" size={IconSizeEnum.MD} />
                <span>Log ind som</span>
              </li>
              {!confirmDeleteUser ? (
                <li
                  onClick={() => setConfirmDeleteUser(true)}
                  tw="cursor-pointer transition-all text-brand-red-500 flex px-4 py-4 items-center space-x-4 hover:(bg-brand-gray-brand)"
                >
                  <Icon icon="trash-alt" size={IconSizeEnum.MD} />
                  <span>Slet bruger</span>
                </li>
              ) : (
                <li tw="cursor-pointer transition-all text-brand-red-500 flex flex-col px-4 py-4 items-start">
                  <span tw="flex items-center space-x-4">
                    <Icon icon="trash-alt" size={IconSizeEnum.MD} />
                    <span>Slet bruger?</span>
                  </span>

                  <ButtonGroup position="center" tw="w-full">
                    <Button
                      onClick={() => setConfirmDeleteUser(false)}
                      size="sm"
                      variant="transparent"
                      tw="text-brand-black-base font-normal m-0"
                    >
                      Annullér
                    </Button>
                    <Button
                      onClick={() => onDeleteUser()}
                      size="sm"
                      variant="danger"
                      tw="m-0"
                    >
                      Bekræft
                    </Button>
                  </ButtonGroup>
                </li>
              )}
            </ul>
          </ContextMenu>
        </>
      }
    >
      <div>
        {selectedUser && (
          <UserForm
            user={selectedUser}
            onSubmit={onSaveHandler}
            basicTitle="Oplysninger"
            settingsTitle="Opsætning"
            saveButtonText="Gem bruger"
            showSettings
          />
        )}
      </div>
    </MainContainer>
  )
}
