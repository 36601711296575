import React from "react"
import { Router } from "@reach/router"
import { GlobalStyles, FontFamilies } from "lib/ui/theme"
import { ThemeProvider } from "styled-components"
import PrivateRoute from "app/PrivateRoute"
import { Helmet } from "react-helmet"
import ResetPassword from "routes/ResetPassword"
import NewPassword from "routes/NewPassword"
import { siteTheme, CustomGlobalStyles, AppGlobalStyles } from "theme"
import { ViewportContext } from "context"
import useViewport from "hooks/useViewport"
import { Home } from "routes/Home"
import { Accounts } from "routes/Accounts"
import { Devices } from "routes/Devices"
import { Users } from "routes/Users"
import { UserProvider } from "app/User/context"
import { AccountsProvider } from "app/Account/context"
import { DevicesProvider } from "app/Devices/context"
import { AccountView } from "routes/Accounts/View"
import { AccountDevices } from "routes/Accounts/View/Devices"
import { CreateAccount } from "routes/Accounts/Create"
import { AccountUsers } from "routes/Accounts/View/Users"
import { UserView } from "routes/Users/View"
import { CreateUser } from "routes/Users/Create"
import { CreateDevice } from "routes/Devices/Create"
import { InstallationProvider } from "app/Installation/context"
import { DeviceConfiguration } from "routes/DeviceConfiguration"
import { ConfigurationProvider } from "app/Configuration/context"

const HomeComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Hub | Clevertrack" />
      <Home />
    </>
  )
}

const AccountsComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Konti | Clevertrack" />
      <Accounts />
    </>
  )
}

const AssignedAccountsComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Tildelte konti | Clevertrack" />
      <Accounts onlyAssignedAccounts />
    </>
  )
}

const AccountViewComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Konto | Clevertrack" />
      <AccountView />
    </>
  )
}

const AccountDevicesComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Kontoenheder | Clevertrack" />
      <AccountDevices />
    </>
  )
}

const AccountUsersComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Kontobrugere | Clevertrack" />
      <AccountUsers />
    </>
  )
}

const AccountCreateComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Opret konto | Clevertrack" />
      <CreateAccount />
    </>
  )
}

const DevicesComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Enheder | Clevertrack" />
      <Devices />
    </>
  )
}

const CreateDevicesComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Opret enheder | Clevertrack" />
      <CreateDevice />
    </>
  )
}

const UsersComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Brugere | Clevertrack" />
      <Users />
    </>
  )
}

const UserViewComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Bruger | Clevertrack" />
      <UserView />
    </>
  )
}

const UserCreateComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Opret ny bruger | Clevertrack" />
      <CreateUser />
    </>
  )
}

const DeviceConfigurationComponent: React.ReactElement = () => {
  return (
    <>
      <Helmet title="Konfigurationer | Clevertrack" />
      <DeviceConfiguration />
    </>
  )
}

function App() {
  const viewport = useViewport()
  if (typeof window === "undefined") return null

  return (
    <ThemeProvider theme={siteTheme}>
      <FontFamilies />
      <GlobalStyles />
      <CustomGlobalStyles />
      <AppGlobalStyles />
      <ViewportContext.Provider value={viewport}>
        <AccountsProvider>
          <UserProvider>
            <ConfigurationProvider>
              <DevicesProvider>
                <InstallationProvider>
                  <Router basepath="/app">
                    <PrivateRoute path="/" component={HomeComponent} />
                    <PrivateRoute
                      path="/accounts"
                      component={AccountsComponent}
                    />
                    <PrivateRoute
                      path="/accounts/create"
                      component={AccountCreateComponent}
                    />
                    <PrivateRoute
                      path="/accounts/:accountID"
                      component={AccountViewComponent}
                    />
                    <PrivateRoute
                      path="/accounts/:accountID/devices"
                      component={AccountDevicesComponent}
                    />
                    <PrivateRoute
                      path="/accounts/:accountID/users"
                      component={AccountUsersComponent}
                    />
                    <PrivateRoute
                      path="/accounts/:accountID/assignedAccounts"
                      component={AssignedAccountsComponent}
                    />
                    <PrivateRoute
                      path="/devices"
                      component={DevicesComponent}
                    />
                    <PrivateRoute
                      path="/devices/create"
                      component={CreateDevicesComponent}
                    />
                    <PrivateRoute
                      path="/configuration"
                      component={DeviceConfigurationComponent}
                    />
                    <PrivateRoute
                      path="/configuration/:configID"
                      component={DeviceConfigurationComponent}
                    />
                    <PrivateRoute path="/users" component={UsersComponent} />
                    <PrivateRoute
                      path="/users/create"
                      component={UserCreateComponent}
                    />
                    <PrivateRoute
                      path="/users/create/:accountID"
                      component={UserCreateComponent}
                    />
                    <PrivateRoute
                      path="/users/:userID"
                      component={UserViewComponent}
                    />
                    <ResetPassword path="/reset-password" />
                    <NewPassword path="/new-password" />
                  </Router>
                </InstallationProvider>
              </DevicesProvider>
            </ConfigurationProvider>
          </UserProvider>
        </AccountsProvider>
      </ViewportContext.Provider>
    </ThemeProvider>
  )
}

export default App

App.defaultProps = {}

App.propTypes = {}
