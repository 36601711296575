import React from "react"
import tw from "twin.macro"
import { Tag } from "@clevertrack/shared"
import { DeviceStockStatusEnum } from "app/Devices/types"
import styled from "styled-components"

const colorThemes = {
  [DeviceStockStatusEnum.Stock]: tw`bg-brand-blue-50`,
  [DeviceStockStatusEnum.Awaiting]: tw`bg-brand-yellow-200`,
  [DeviceStockStatusEnum.Installed]: tw`bg-green-600`,
}

const StyledTag = styled(Tag)`
  ${tw`max-w-max`}
`

export const StockType: React.FC<{
  stockStatus: DeviceStockStatusEnum
  formatData: (any) => void
}> = ({ stockStatus, formatData, ...props }) => {
  if (!stockStatus) return null
  return (
    <span tw="flex space-x-2">
      <StyledTag show css={colorThemes[stockStatus]}>
        {formatData ? formatData(stockStatus) : stockStatus}
      </StyledTag>
    </span>
  )
}
