import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import {
  ref,
  child,
  onValue,
  query,
  goOffline,
  goOnline,
  Unsubscribe,
  onChildChanged,
  orderByChild,
  startAt,
  endAt,
} from "firebase/database"

export const useRealtimeDeviceValues = () => {
  const { rtdb } = useContext(FirebaseContext)

  const dataRef = ref(rtdb, `vehicles`)
  const deviceInstallationsDataRef = ref(rtdb, `devices`)

  const getDeviceValuesByCompanyID = (companyID, onValueHandler) => {
    const q = query(
      dataRef,
      orderByChild("companyID"),
      startAt(companyID),
      endAt(companyID)
    )
    const unsub = onValue(q, onValueHandler)
    return unsub
  }

  const getDeviceValuesByID = (vehicleID, onValueHandler) => {
    const q = query(child(dataRef, vehicleID))
    const unsub = onValue(q, onValueHandler)
    return unsub
  }

  const getInstallationDeviceValuesByIMEI = (imei, onValueHandler) => {
    const q = query(child(deviceInstallationsDataRef, imei))
    const unsub = onValue(q, onValueHandler)
    return unsub
  }

  const getDeviceValues = async (
    onValueHandler,
    keys: string[]
  ): Unsubscribe => {
    const unsub = onChildChanged(dataRef, (snapshot) => {
      if (
        snapshot.exists() &&
        keys.includes(snapshot.key?.replace("vehicles/", ""))
      )
        onValueHandler(snapshot.val())
    })
    return unsub
  }

  return {
    getDeviceValuesByID,
    getDeviceValuesByCompanyID,
    getInstallationDeviceValuesByIMEI,
    getDeviceValues,
    goOffline: () => goOffline(rtdb),
    goOnline: () => goOnline(rtdb),
  }
}
