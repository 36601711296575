import React, { useContext } from "react"
import "twin.macro"
import { ConfigurationContext } from "../context"
import styled from "styled-components"
import { ApiDisplayKey } from "app/Devices/types"
import Checkbox from "lib/Checkbox"
import { toggleItemInEnumArray } from "utils/array"
import { ConfigurationActions, ConfigurationTypes } from "../actions"
import tw from "twin.macro"
import { Tag } from "@clevertrack/shared"

const StyledDisplayKeyView = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
`

type DisplayKeysType = {}

export const SelectedDisplayKeys: React.FC = ({ ...props }) => {
  const {
    state: { configuration, availableDisplayKeys },
  } = useContext(ConfigurationContext)

  const displayKeyMap = configuration?.displayKeys.map((x) => {
    const item = availableDisplayKeys.find((key) => key.id === x)
    if (item)
      return (
        <Tag
          key={item.id}
          show
          title={item.keyName}
          tw="m-0 mr-4 mb-2 bg-brand-gray-base"
        />
      )
    return null
  })

  return (
    <span tw="flex flex-col">
      <span>Vælg display keys +</span>
      {displayKeyMap && displayKeyMap.length > 0 && (
        <div tw="flex flex-wrap my-4 p-0 pointer-events-none">
          {displayKeyMap}
        </div>
      )}
    </span>
  )
}

export const DisplayKeys: React.FC<DisplayKeysType> = ({ ...props }) => {
  const {
    state: { availableDisplayKeys, configuration },
    dispatch,
  } = useContext(ConfigurationContext)

  const onChangeHandler = (toggled, displayKeyID) => {
    if (configuration?.displayKeys) {
      const newDisplayKeys = toggleItemInEnumArray<number>(
        configuration?.displayKeys,
        displayKeyID
      )
      dispatch(
        ConfigurationActions(ConfigurationTypes.UpdateConfigurationByID, {
          configuration: {
            ...configuration,
            displayKeys: newDisplayKeys,
          },
        })
      )
    }
  }

  return (
    <div tw="bg-white p-4 pt-6">
      <h3>Vælg Display Keys</h3>
      <StyledDisplayKeyView>
        {availableDisplayKeys.map((displayKey: ApiDisplayKey) => {
          return (
            <div key={displayKey.id}>
              <Checkbox
                checked={configuration?.displayKeys.includes(displayKey.id)}
                onChange={(checked) => onChangeHandler(checked, displayKey.id)}
              >
                <span tw="space-y-2">
                  <span tw="font-bold block mr-4">
                    {displayKey.description}
                  </span>
                  <span tw="block">{displayKey.keyName}</span>
                </span>
              </Checkbox>
            </div>
          )
        })}
      </StyledDisplayKeyView>
    </div>
  )
}
