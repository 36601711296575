import React, { useContext, useEffect, useState } from "react"
import "twin.macro"
import { ConfigurationContext } from "../context"
import { Form, FormField, ValidationInput } from "@clevertrack/shared"
import { Select } from "lib/Select"
import { hintTheme } from "app/Hint"
import { useFormData } from "hooks/useFormData"
import { DeviceCANFeatureEnum } from "app/Devices/types"
import { useDeviceConfiguration } from "../hooks"
import {
  basicIdleReadInputOptions,
  engineHourInputOptions,
  fuelLitreInputOptions,
  idleReadInputOptions,
  mileageInputOptions,
  rpmIdleReadInputOptions,
} from "../helper"
import isEqual from "lodash-es/isEqual"

type FeaturesType = {}

/**
 * Tomgangsmåling
 *
 *
 *
 * Motortimer
 *
 *
 *
 * Brændstofmåling
 *
 *
 *
 * Km-tæller
 *
 *
 *
 *
 */

export const DeviceFeatures: React.FC<FeaturesType> = ({ ...props }) => {
  const {
    state: { configuration },
  } = useContext(ConfigurationContext)
  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    { [key in DeviceCANFeatureEnum]: number } | {}
  >(configuration?.features ?? {})

  const { updateConfigByID } = useDeviceConfiguration()

  useEffect(() => {
    if (!isEqual(currentModel, configuration?.features)) {
      updateConfigByID({
        ...configuration,
        features: currentModel,
      })
    }
  }, [currentModel])

  return (
    <Form tw="w-full">
      <div tw="bg-white p-4 pt-6 w-3/4">
        <h3>Opsætning</h3>
        <div tw="space-y-8">
          <h4>Tomgangsmåling</h4>
          <FormField label="Metode til registrering af tomgang" tw="z-40">
            <Select
              menuPlacement="auto"
              placeholder="Vælg hvordan emnet skal registrere tomgang"
              options={idleReadInputOptions}
              onChange={(opt) =>
                updateModel(DeviceCANFeatureEnum.IdleReadInput, opt.value)
              }
              value={[
                ...basicIdleReadInputOptions,
                ...rpmIdleReadInputOptions,
              ].find((opt) => opt.value === currentModel?.idleReadInput)}
              tw="pt-4"
            />
          </FormField>
          {[5, 7].includes(currentModel?.idleReadInput) && (
            <span tw="inline-block" css={hintTheme.default}>
              <strong tw="block">Bemærk! </strong>
              <span tw="mt-2 block">
                Når 'CAN hastighed = 0' er valgt, benyttes CAN hastigheden også
                til at vise emnets hastighed i webapp'en.
              </span>
            </span>
          )}
          {[1, 3].includes(currentModel?.idleReadInput) && (
            <>
              <span tw="inline-block" css={hintTheme.default}>
                <strong tw="block">Motoromdrejninger </strong>
                <span tw="mt-2 block">
                  Nedre grænseværdi: Motoromdrejninger ved tomgang.
                  <br />
                  Øvre grænseværdi: Motoromdrejninger ved arbejde.
                </span>
              </span>
              <div tw="flex items-center space-x-4 space-y-0 my-8">
                <FormField tw="flex-1 z-30" label="Nedre grænseværdi (RPM Low)">
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.RPM_low}
                    placeholder="Angiv antal omdrejninger"
                    onChange={(e) =>
                      updateModel(DeviceCANFeatureEnum.RPMLow, e.target.value)
                    }
                  />
                </FormField>
                <FormField tw="flex-1 z-30" label="Øvre grænseværdi (RPM High)">
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.RPM_high}
                    placeholder="Angiv antal omdrejninger"
                    onChange={(e) =>
                      updateModel(DeviceCANFeatureEnum.RPMHigh, e.target.value)
                    }
                  />
                </FormField>
              </div>
            </>
          )}
          {currentModel?.idleReadInput === 2 && (
            <>
              <div tw="flex flex-col w-3/4 space-y-4 space-y-0 my-8">
                <FormField
                  tw="flex-1 z-30"
                  label="Trykføler nedre grænseværdi (volt)"
                >
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.AIN2_low}
                    placeholder="Angiv volt"
                    onChange={(e) =>
                      updateModel(DeviceCANFeatureEnum.AIN2Low, e.target.value)
                    }
                  />
                </FormField>
                <FormField
                  tw="flex-1 z-30"
                  label="Trykføler øvre grænseværdi (volt)"
                >
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.AIN2_high}
                    placeholder="Angiv volt"
                    onChange={(e) =>
                      updateModel(DeviceCANFeatureEnum.AIN2High, e.target.value)
                    }
                  />
                </FormField>
                <FormField tw="flex-1 z-30" label="Trykføler nul-værdi (volt)">
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.AIN2_zero}
                    placeholder="Angiv volt"
                    onChange={(e) =>
                      updateModel(DeviceCANFeatureEnum.AIN2Zero, e.target.value)
                    }
                  />
                </FormField>
              </div>
            </>
          )}
          {currentModel?.idleReadInput === 4 && (
            <>
              <span tw="inline-block" css={hintTheme.default}>
                <strong tw="block">Motorbelastning </strong>
                <span tw="mt-2 block">
                  Nedre grænseværdi: Motorbelastning i % ved tomgang.
                  <br />
                  Øvre grænseværdi: Motorbelastning i % ved arbejde.
                </span>
              </span>

              <div tw="flex items-center space-x-4 space-y-0 my-8">
                <FormField tw="flex-1 z-30" label="Nedre grænseværdi (%)">
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.EngineLoadLow}
                    placeholder="Angiv motorbelastning %"
                    onChange={(e) =>
                      updateModel(
                        DeviceCANFeatureEnum.EngineLoadLow,
                        e.target.value
                      )
                    }
                  />
                </FormField>
                <FormField
                  tw="flex-1 z-30"
                  label="Øvre grænseværdi (Motorbelastning i %)"
                >
                  <ValidationInput
                    id="name"
                    type="number"
                    defaultValue={configuration?.features?.EngineLoadHigh}
                    placeholder="Angiv motorbelastning i %"
                    onChange={(e) =>
                      updateModel(
                        DeviceCANFeatureEnum.EngineLoadHigh,
                        e.target.value
                      )
                    }
                  />
                </FormField>
              </div>
            </>
          )}
          <h4>Motortimer</h4>
          <FormField label="Metode til registrering af motortimer" tw="z-30">
            <Select
              menuPlacement="auto"
              placeholder="Vælg hvordan enheden skal tælle motortimer"
              options={engineHourInputOptions}
              onChange={(opt) =>
                updateModel(DeviceCANFeatureEnum.EngineHourInput, opt.value)
              }
              value={engineHourInputOptions.find(
                (opt) => opt.value === currentModel?.engineHourInput
              )}
              tw="pt-4"
            />
          </FormField>
          <h4>Brændstofmåling</h4>
          <FormField
            label="Metode til registrering af brændstofforbrug"
            tw="z-20"
          >
            <Select
              menuPlacement="auto"
              placeholder="Vælg hvordan enheden skal måle brændstofforbrug"
              options={fuelLitreInputOptions}
              onChange={(opt) =>
                updateModel(DeviceCANFeatureEnum.FuelLitreInput, opt.value)
              }
              value={fuelLitreInputOptions.find(
                (opt) => opt.value === currentModel?.fuelLitreInput
              )}
              tw="pt-4"
            />
          </FormField>
          <h4>Km-tæller</h4>
          <FormField label="Metode til optælling af kørte kilometer" tw="z-10">
            <Select
              menuPlacement="auto"
              placeholder="Vælg hvordan enheden skal måle kilometer"
              options={mileageInputOptions}
              onChange={(opt) =>
                updateModel(DeviceCANFeatureEnum.MileageInput, opt.value)
              }
              value={mileageInputOptions.find(
                (opt) => opt.value === currentModel?.mileageInput
              )}
              tw="pt-4"
            />
          </FormField>
        </div>
      </div>
    </Form>
  )
}
