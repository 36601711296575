import React, { useEffect, useState } from "react"
import { Icon } from "@clevertrack/shared"
import { IconSizeEnum } from "lib/Icon"
import "twin.macro"
import Accordion from "lib/Accordion"

type FormFieldToggleType = {
  untoggleText?: string
  toggleText: string
  toggleQuestion?: string | null
  defaultToggled?: boolean
  onEnable?: () => void
  onDisable?: () => void
}

export const FormFieldToggle: React.FC<FormFieldToggleType> = ({
  toggleText,
  toggleQuestion,
  untoggleText = "Fjern",
  defaultToggled,
  onEnable,
  onDisable,
  children,
  ...props
}) => {
  const [toggled, setToggled] = useState(defaultToggled)

  useEffect(() => {
    if (toggled && onEnable) onEnable()
    if (!toggled && onDisable) onDisable()
  }, [toggled])

  return (
    <div {...props}>
      <Accordion toggled={toggled}>
        {children}
        <div tw="block ml-4 mt-2">
          {toggleQuestion && <span tw="text-lg mr-2">{toggleQuestion}</span>}
          <span
            tw="text-brand-red-500 inline-flex items-center cursor-pointer hover:opacity-60 transition"
            onClick={() => setToggled(false)}
          >
            <span tw="text-lg">{untoggleText}</span>
            <Icon size={IconSizeEnum.SM} icon="close" tw="ml-2" />
          </span>
        </div>
      </Accordion>
      {!toggled && (
        <span
          tw="flex mt-0 mb-8 px-4 items-center text-brand-500 cursor-pointer hover:opacity-60 transition"
          onClick={() => setToggled(true)}
        >
          {toggleText}
        </span>
      )}
    </div>
  )
}
