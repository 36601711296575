import React, { Component } from "react"
import ReactDOM from "react-dom"
import OffCanvasInner, { IOffCanvasInnerProps } from "./OffCanvasInner"

export interface IOffCanvasProps extends IOffCanvasInnerProps {
  children?: React.ReactNode
  selector?: string
}

class OffCanvas extends Component<IOffCanvasProps> {
  private offcanvasRoot: any

  constructor(props) {
    super(props)
    this.offcanvasRoot = null
    /* if (typeof window !== "undefined") {
      if (typeof props.selector === "object" && props.selector !== null) {
        this.offcanvasRoot = props.selector
      } else {
        this.offcanvasRoot = document.querySelector(
          props.selector ?? "#___offcanvas"
        )
      }
    } */
  }

  componentDidUpdate(props) {
    if (typeof props.selector === "object" && props.selector !== null) {
      this.offcanvasRoot = props.selector
    } else {
      this.offcanvasRoot = document.querySelector(
        props.selector ?? "#___offcanvas"
      )
    }
  }

  componentDidMount() {
    if (
      typeof this.props.selector === "object" &&
      this.props.selector !== null
    ) {
      this.offcanvasRoot = this.props.selector
    } else {
      this.offcanvasRoot = document.querySelector(
        this.props.selector ?? "#___offcanvas"
      )
    }
  }

  render() {
    if (typeof window === "undefined" || this.offcanvasRoot === null)
      return null
    const { children, selector, ...props } = this.props
    return ReactDOM.createPortal(
      <OffCanvasInner {...props}>{children}</OffCanvasInner>,
      this.offcanvasRoot
    )
  }
}

export default OffCanvas
