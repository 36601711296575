import { useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "@gatsbyjs/reach-router"
import { UserTypeEnum, UserTypes } from "./types"
import { UserContext } from "./context"
import { AccountsContext } from "app/Account/context"
import { ApiAccountTypeEnum } from "app/Account/types"

export const useUser = () => {
  const [selectedUser, setSelectedUser] = useState<UserTypes | null>(null)
  const [
    currentUserIsExternalFitter,
    setCurrentUserIsExternalFitter,
  ] = useState<boolean>(false)
  const { userID } = useParams()
  const {
    state: { user, users },
  } = useContext(UserContext)
  const {
    state: { account },
  } = useContext(AccountsContext)

  useEffect(() => {
    if (!selectedUser && users.length > 0) {
      const user = users.find((user) => +user.id === +userID)
      if (user) setSelectedUser(user)
    }
  }, [selectedUser, users, userID])

  useMemo(() => {
    if (
      account?.description !== ApiAccountTypeEnum.Installer &&
      (user?.userTypes?.includes(UserTypeEnum.FITTER) ||
        user?.userTypes?.includes(UserTypeEnum.ADMIN) ||
        user?.is_admin === 1)
    ) {
      setCurrentUserIsExternalFitter(true)
    } else setCurrentUserIsExternalFitter(false)
  }, [user, account])

  return { selectedUser, currentUserIsExternalFitter }
}
