import React, { useReducer, createContext } from "react"
import { DevicesActionMap, DevicesTypes } from "./actions"
import { Device } from "./types"
import {
  updateCollectionItemByID,
  updateCollectionItemByIndex,
} from "utils/array"

/**
 * Set initial state and create the context. The Provider can be wrapped around any component up the tree
 * This approach enables us to keep things really modular
 */

type InitialDevicesStateType = {
  devices: Device[] | []
  files: any[]
}

const initialState: InitialDevicesStateType = {
  devices: [],
  files: [],
}
const DevicesContext = createContext<{
  state: InitialDevicesStateType
  dispatch: React.Dispatch<any>
}>({ state: initialState, dispatch: () => null })

/**
 * Tracker reducer
 * Keeps track of selected trackers and the trackers loaded in from the backend
 */

const devicesReducer = (
  state: InitialDevicesStateType,
  action: DevicesActionMap
) => {
  switch (action.type) {
    case DevicesTypes.SetDevices:
      return {
        ...state,
        devices: action.payload.devices,
      }
    case DevicesTypes.UpdateDeviceByID:
      if (state.devices) {
        const deletedDevices = state.devices.filter((x) => x.deleted)
        const newDevices = updateCollectionItemByIndex<Device>(
          state.devices.filter((x) => !x.deleted),
          action.payload.device.imei,
          action.payload.device,
          "imei"
        )
        return {
          ...state,
          devices: [...newDevices, ...deletedDevices],
        }
      }
      return state
    case DevicesTypes.SetConfigurationFiles:
      return {
        ...state,
        files: action.payload.files,
      }
    default:
      return state
  }
}

const DevicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(devicesReducer, initialState)

  return (
    <DevicesContext.Provider value={{ state, dispatch }}>
      {children}
    </DevicesContext.Provider>
  )
}

export { DevicesContext, DevicesProvider }
