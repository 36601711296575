import { integrationApi } from "utils/api"

export const getLicensePlateInfo = async (reg) => {
  const result = await integrationApi({
    baseURL: "https://api.synsbasen.dk/v1",
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_SYNSBASEN_API_KEY}`,
    },
  }).get(`/vehicles/registration/${reg}`)
  return result
}
