import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import { Functions, httpsCallable } from "firebase/functions"

export enum FirebaseFunctionsEnum {
  SendWelcomeEmail = "sendWelcomeEmail",
  GetFotaDeviceByImei = "getFotaDeviceByImei",
  GetInactiveFotaDevices = "getInactiveFotaDevices",
  GetFotaConfigurationFiles = "getConfigurationFiles",
  UpdateDeviceConfiguration = "updateFotaDeviceConfig",
}

export const useFirebaseFunctions = () => {
  const { functions } = useContext(FirebaseContext)

  // FOTA API
  const getFotaDevice = httpsCallable<{ deviceImei: number }>(
    functions as Functions,
    FirebaseFunctionsEnum.GetFotaDeviceByImei
  )

  const getFotaConfigurationFiles = httpsCallable<
    null,
    { files: any[]; status: string }
  >(functions as Functions, FirebaseFunctionsEnum.GetFotaConfigurationFiles)

  const getInactiveFotaDevices = httpsCallable<{ shipmentID: string | null }>(
    functions as Functions,
    FirebaseFunctionsEnum.GetInactiveFotaDevices
  )

  const updateFotaDeviceConfig = httpsCallable<
    { imeiList: number[]; fileID: number },
    { status: string; data: any }
  >(functions as Functions, FirebaseFunctionsEnum.UpdateDeviceConfiguration)

  return {
    getFotaDevice,
    getInactiveFotaDevices,
    getFotaConfigurationFiles,
    updateFotaDeviceConfig,
  }
}
