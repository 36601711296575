import { useCallback, useState } from "react"
import { useFirebaseStorage } from "services/storage"
import { FileObj, UploadProgressMap } from "./documentupload.types"
import { getDownloadURL } from "firebase/storage"
import cogoToast from "@clevertrackdk/cogo-toast"

export const useFileUpload = (path, accountID) => {
  const { uploadFile, getDirectoryList, deleteFile } = useFirebaseStorage()
  const [uploadMap, setUploadMap] = useState<UploadProgressMap>({})
  const [inProgress, setInProgress] = useState(false)

  const onUploadDocumentHandler = async (
    data: FileObj[],
    filename: string,
    key?: string
  ) => {
    if (accountID) {
      setInProgress(true)
      for (const file of data) {
        const fileID = file.id
        const filePath = [path, filename ?? file.file.name]
        const uploadTask = uploadFile(filePath.join("/"), file.file)
        setUploadMap((prev) => ({
          ...prev,
          [fileID]: {
            progress: `0%`,
            filename: filename ?? file.file.name,
            path: filePath.join("/"),
            key: key ?? null,
          },
        }))
        if (uploadTask) {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setUploadMap((prev) => ({
                ...prev,
                [fileID]: {
                  ...prev[fileID],
                  progress: `${progress}%`,
                  key: key ?? null,
                },
              }))
            },
            (error) => {
              // Handle unsuccessful uploads
              cogoToast.error(`Der skete en fejl under upload: ${error}`)
            },
            async () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
              setUploadMap((prev) => ({
                ...prev,
                [fileID]: {
                  ...prev[fileID],
                  downloadURL,
                  key: key ?? null,
                },
              }))
            }
          )
        }
      }
    }
  }

  const removeItemFromUploadMapByKey = useCallback(
    (key: string) => {
      const newUploadMap = {}
      Object.entries(uploadMap).forEach(([k, value]) => {
        if (value.key !== key) {
          newUploadMap[k] = value
        }
      })
      setUploadMap(newUploadMap)
    },
    [uploadMap]
  )

  return {
    onUploadDocumentHandler,
    uploadMap,
    removeItemFromUploadMapByKey,
    inProgress,
  }
}
