import { useContext } from "react"
import { FirebaseContext } from "context/FirebaseProvider"
import { doc, setDoc } from "firebase/firestore"

export const useFirestoreInstallationGuide = () => {
  const { db } = useContext(FirebaseContext)

  const createInstallationGuideEntry = async (
    make,
    model,
    year,
    vehicleID,
    imagePath
  ) => {
    if (db) {
      try {
        const firebaseInstallationGuide = doc(
          db,
          `installationguide/${vehicleID}`
        )
        await setDoc(
          firebaseInstallationGuide,
          {
            sourceVehicleID: vehicleID,
            make,
            model,
            year,
            photoFolder: imagePath,
          },
          { merge: true }
        )
        return {
          result: "OK",
        }
      } catch (error) {
        console.log(error)
        return {
          result: "Error",
        }
      }
    }
  }

  return {
    createInstallationGuideEntry,
  }
}
