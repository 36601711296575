import { useContext, useEffect, useMemo } from "react"
import { getAccountVehicleGroups, getAccountVehicles } from "services/accounts"
import { InstallationContext } from "./context"
import { InstallationActions, InstallationTypes } from "./actions"
import { DevicesContext } from "app/Devices/context"
import {
  Beacon,
  DeviceTypeCategoryEnum,
  StockDevice,
  Tracker,
} from "app/Devices/types"
import {
  assetTracker,
  gpsTrackerDeviceTypes,
  gpsTrackerWithBattery,
  gpsTrackerWithCANDeviceTypes,
} from "./Flow/helper"
import { InstallationScreenEnum } from "./types"
import { useFirebaseFunctions } from "services/firebase-functions/functions"
import { DevicesActions, DevicesTypes } from "app/Devices/actions"
import { deviceTypeObject } from "app/Devices/helper"

export const useInstallation = () => {
  const {
    state: { vehicles, account, accountDevices, device },
    dispatch,
  } = useContext(InstallationContext)
  const {
    state: { devices },
    dispatch: devicesDispatch,
  } = useContext(DevicesContext)
  const { getFotaDevice } = useFirebaseFunctions()

  const getAccountApiVehicles = async (accountID) => {
    // Needs rework, to reflect newly updated installations better
    const response = await getAccountVehicles(accountID)
    const groupResponse = await getAccountVehicleGroups(accountID)

    if (response.result === "OK" && response.accountVehicles) {
      dispatch(
        InstallationActions(InstallationTypes.SetVehicles, {
          accountVehicles: response.accountVehicles.filter((x) => !x.deleted),
        })
      )
    }

    if (groupResponse.result === "OK" && groupResponse.groups) {
      dispatch(
        InstallationActions(InstallationTypes.SetAccountVehicleGroups, {
          groups: groupResponse.groups,
        })
      )
    }
  }

  const getFotaDevicByIMEI = async (imei) => {
    const fotaReq = await getFotaDevice({ deviceImei: imei })
    if (fotaReq.data.status === "OK" && fotaReq.data.device) {
      dispatch(
        InstallationActions(InstallationTypes.SetFotaDeviceData, {
          fotaDevice: fotaReq.data.device,
        })
      )
    }
  }

  const deviceTypeCategory = useMemo(() => {
    if (+device?.deviceType) {
      if (gpsTrackerDeviceTypes.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTracker
      if (gpsTrackerWithCANDeviceTypes.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithCAN
      if (gpsTrackerWithBattery.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.GPSTrackerWithBattery
      if (assetTracker.includes(+device?.deviceType))
        return DeviceTypeCategoryEnum.Beacon
    }
    return null
  }, [device])

  useEffect(() => {
    if (
      account &&
      devices &&
      devices.length > 0 &&
      vehicles &&
      vehicles.length > 0
    ) {
      const devicesByAccountID = devices
        .filter(
          (x) =>
            +x?.accountID === +account.id ||
            +x?.installationStartedOnAccountID === +account.id
        )
        .filter((x) => x.imei !== null)

      const reducedDevices: Partial<
        (Beacon | Tracker) & StockDevice
      >[] = devicesByAccountID.reduce((accDevices, currDevice) => {
        // First, check if this device already exists in accumulator
        const indexOfDevice = accDevices?.findIndex(
          (dev) => +dev.imei === +currDevice.imei
        )

        if (indexOfDevice > -1) {
          accDevices[indexOfDevice] = {
            ...accDevices[indexOfDevice],
            ...currDevice,
          }
          // Device found, add current device data to the same element
          return accDevices
        } else {
          // Find the vehicle matching device imei
          const vehicleData = vehicles.find(
            (vehicle) => +vehicle.imei === +currDevice.imei
          )

          if (!vehicleData || !currDevice.imei) {
            // E.g. imei missing from device data
            const vehicleDataByID = vehicles
              .filter((x) => x.deleted === false)
              .find((vehicle) => +vehicle.id === +currDevice.id)

            accDevices.push({
              ...vehicleDataByID,
              ...currDevice,
              id: vehicleDataByID?.id ?? currDevice?.id ?? null,
              unit_id: currDevice.unit_id,
              name: vehicleDataByID?.name ?? currDevice.imei,
              deviceType: currDevice?.deviceType.toString(),
              deviceTypeName:
                currDevice?.deviceTypeName ??
                deviceTypeObject.find((x) => x.value === currDevice?.deviceType)
                  ?.label,
            })
            return accDevices
          } else {
            accDevices.push({
              ...vehicleData,
              ...currDevice,
              id: vehicleData.id,
              name: vehicleData?.name ?? currDevice.imei,
              unit_id: currDevice.unit_id,
              deviceType: currDevice?.deviceType,
              deviceTypeName:
                currDevice?.deviceTypeName ??
                deviceTypeObject.find((x) => x.value === currDevice?.deviceType)
                  ?.label,
            })
            return accDevices
          }
        }
      }, [])

      dispatch(
        InstallationActions(InstallationTypes.SetAccountDevices, {
          accountDevices: reducedDevices,
        })
      )
      return
    }

    dispatch(
      InstallationActions(InstallationTypes.SetAccountDevices, {
        accountDevices: [],
      })
    )
  }, [devices, account, vehicles])

  const setCurrentScreen = (screen: InstallationScreenEnum) => {
    dispatch(InstallationActions(InstallationTypes.SetScreen, screen))
  }

  return {
    getAccountApiVehicles,
    getFotaDevicByIMEI,
    deviceTypeCategory,
    setCurrentScreen,
  }
}
