import { DeviceTypeEnum } from "app/Devices/types"
import {
  DeviceCalibrationModel,
  DeviceHealthValidationModel,
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepDataProps,
  InstallationStepOptionalDataProps,
  InstallationStepStateEnum,
  InstallationVerificationModel,
} from "../types"
import { Account, InstallerAccountFeatureEnum } from "app/Account/types"

export const generateDeviceHealthStep = (): InstallationStep => {
  const data: DeviceHealthValidationModel = {
    devicePower: {
      valid: false,
      value: "",
    },
    deviceIgnition: {
      valid: false,
      value: "",
    },
    deviceSatcountOK: {
      valid: false,
      value: "",
    },
    deviceInternalBatteryOK: {
      valid: false,
      value: "",
    },
  }
  return {
    stepID: InstallationScreenEnum.DeviceHealthValidation,
    stepState: InstallationStepStateEnum.NotStarted,
    data,
    optionalDataProps: [],
  }
}

export const generateDeviceInformationStep = (apiData): InstallationStep => {
  const data: DeviceInformationModel = {
    legacyVehicleName: apiData?.name,
    vehicleNumber: "",
    vehicleDescription: apiData?.note ? apiData.note : "",
    vehicleBrand: "",
    vehicleYear: "",
    vehicleModel: "",
    vehicleRegistrationNumber: "",
    vehicleLicensePlatePhoto: "",
    vehicleLicensePlatePhotoPath: "",
    vehicleFrameNumber: "",
    vehicleTypePlatePhoto: "",
    vehicleTypePlatePhotoPath: "",
    vehicleName: apiData.name ?? "",
  }
  return {
    stepID: InstallationScreenEnum.DeviceInformation,
    stepState: InstallationStepStateEnum.NotStarted,
    data,
    optionalDataProps: [
      "vehicleUniqueness",
      "legacyVehicleName",
      "vehicleGroup",
    ],
  }
}

export const generateInstallationVerificationStep = (
  apiData,
  account: Account
): InstallationStep => {
  const data: InstallationVerificationModel = {
    deviceMountingPhoto: "",
    deviceMountingPhotoPath: "",
    deviceMountingNote: apiData?.description ?? "",
    devicePlacementPhoto: "",
    devicePlacementPhotoPath: "",
    vehicleFrontPhoto: "",
    vehicleFrontPhotoPath: "",
  }

  if (
    ![...gpsTrackerWithBattery, ...assetTracker].includes(+apiData?.deviceType)
  ) {
    data.isMainSwitchPresent = null
  }

  if (gpsTrackerWithCANDeviceTypes.includes(+apiData?.deviceType)) {
    data.deviceCANDataReaderCount = null
    data.deviceCANDataReaderPhoto = ""
    data.deviceCANDataReaderPhotoPath = ""
    data.deviceCANDataReaderNote = ""
    data.deviceCANHighColor = ""
    data.deviceCANLowColor = ""
    data.deviceCANDataReaderCount = null
  }

  if (gpsTrackerWithAutoOffSupport.includes(+apiData?.deviceType)) {
    data.autoOffMounted = null
  }

  const optionalDataProps: (keyof InstallationVerificationModel)[] = [
    "devicePlacementNote",
    "deviceCANDataReaderNote",
    "deviceCAN2DataReaderNote",
    "vehicleFrontNote",
    "autoOffRelayMountNote",
    "autoOffTurnsOffDisplay",
    "autoOffTurnsOffLight",
    "autoOffRequiresTurnKey",
  ]

  if (
    account.installerAccountFeatures?.includes(
      InstallerAccountFeatureEnum.SkipCANDataDocumentation
    )
  ) {
    optionalDataProps.push(
      "deviceCANDataReaderCount",
      "deviceCANDataReaderPhoto",
      "deviceCANDataReaderPhotoPath",
      "deviceCANHighColor",
      "deviceCANLowColor",
      "deviceCAN2DataReaderPhoto",
      "deviceCAN2DataReaderPhotoPath",
      "deviceCAN2HighColor",
      "deviceCAN2LowColor"
    )
  }

  return {
    stepID: InstallationScreenEnum.InstallationVerification,
    stepState: InstallationStepStateEnum.NotStarted,
    data,
    optionalDataProps,
  }
}

export const generateDeviceCalibrationStep = (apiData): InstallationStep => {
  const data: DeviceCalibrationModel = {
    totalDistance:
      apiData?.calibration?.mileage === 0
        ? null
        : apiData?.calibration?.mileage.toString(),
    totalEngineHours:
      apiData?.calibration?.totalEngineHours === 0
        ? null
        : apiData?.calibration?.totalEngineHours,
  }

  const optionalDataProps: (keyof DeviceCalibrationModel)[] = [
    "deviceConfiguration",
    "deviceIdleConfiguration",
  ]

  switch (apiData?.idleReadInput) {
    case 1:
    case 3:
      optionalDataProps.push("zeroIdleAIN1", "lowerIdleAIN1", "engineLoadLow")
      data.lowerIdleRPM = apiData?.features?.lowerIdleRPM ?? null
      break
    case 2:
      optionalDataProps.push("engineLoadLow", "lowerIdleRPM")
      data.zeroIdleAIN1 = apiData?.features?.zeroIdleAIN1 ?? null
      data.lowerIdleAIN1 = apiData?.features?.lowerIdleAIN1 ?? null
      break
    case 4:
      optionalDataProps.push("zeroIdleAIN1", "lowerIdleAIN1", "lowerIdleRPM")
      data.engineLoadLow = apiData?.features?.engineLoadLow ?? null
      break
  }

  return {
    stepID: InstallationScreenEnum.DeviceCalibration,
    stepState: InstallationStepStateEnum.NotStarted,
    data,
    optionalDataProps,
  }
}

export const getInstallationStepState = (
  model: InstallationStepDataProps,
  optionalDataProps: (keyof InstallationStepOptionalDataProps)[]
) => {
  if (!optionalDataProps) throw new Error("No optionalDataProps found")
  // All values of a model, must contain a value, unless their key is included in optionalDataProps
  const values = []
  Object.entries(model).forEach(
    ([key, value]: [any, InstallationStepDataProps]) => {
      if (!optionalDataProps.includes(key)) values.push(value)
    }
  )
  if (!values.some((val) => val === null || val === ""))
    return InstallationStepStateEnum.Completed
  return InstallationStepStateEnum.Incomplete
}

export const gpsTrackerDeviceTypes = [
  DeviceTypeEnum["FMB001 (biler)"],
  DeviceTypeEnum["FMB002 (biler)"],
  DeviceTypeEnum["FMB003 (biler)"],
  DeviceTypeEnum["FMB010 (biler)"],
  DeviceTypeEnum.FMB125,
  DeviceTypeEnum["FMB125 (maskiner m. fuel flow måler)"],
  DeviceTypeEnum["FMB130 (biler)"],
  DeviceTypeEnum["FMB204 (biler)"],
  DeviceTypeEnum["FMB204 (materielscanner)"],
  DeviceTypeEnum["FMB204 (trailere)"],
  DeviceTypeEnum["FMB204 (maskiner m. trykføler)"],
  DeviceTypeEnum["FMB204 (maskiner/materiel)"],
  DeviceTypeEnum["FMB204 m. AEMP (maskiner)"],
  DeviceTypeEnum["FMC234 (maskiner)"],
  DeviceTypeEnum["FMC234 (maskiner m. trykføler)"],
  DeviceTypeEnum.FMB001,
  DeviceTypeEnum.FMB003,
  DeviceTypeEnum.FMB204,
  DeviceTypeEnum.FMC234,
  DeviceTypeEnum.FMC125,
  DeviceTypeEnum.FMB130,
]

export const gpsTrackerWithCANDeviceTypes = [
  DeviceTypeEnum["FMB140 ALL-CAN (biler u. brændstofdata)"],
  DeviceTypeEnum["FMB140 ALL-CAN (biler)"],
  DeviceTypeEnum["FMB140 ALL-CAN (el-biler)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. AEMP)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. trykføler + AEMP)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. trykføler)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner u. brændstofdata)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner u. CAN)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner)"],
  DeviceTypeEnum["FMB140 LV-CAN (biler u. brændstofdata)"],
  DeviceTypeEnum["FMB140 LV-CAN (biler)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner m. AEMP)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner m. trykføler + AEMP)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner m. trykføler)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner)"],
  DeviceTypeEnum["FMB240 ALL-CAN (maskiner)"],
  DeviceTypeEnum["FMB240 LV-CAN (biler)"],
  DeviceTypeEnum["FMB640 (biler)"],
  DeviceTypeEnum["FMC150 (maskiner)"],
  DeviceTypeEnum.FMC150,
  DeviceTypeEnum["FMC150 ext"],
  DeviceTypeEnum.FMC250,
  DeviceTypeEnum["FMC250 ext"],
  DeviceTypeEnum.FMB640,
  DeviceTypeEnum["FMB140 LV-CAN"],
  DeviceTypeEnum["FMB140 ALL-CAN"],
  DeviceTypeEnum["FMB240 LV-CAN"],
  DeviceTypeEnum["FMB240 ALL-CAN"],
]

export const gpsTrackerWithAutoOffSupport = [
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. AEMP)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. trykføler + AEMP)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner m. trykføler)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner u. brændstofdata)"],
  DeviceTypeEnum["FMB140 ALL-CAN (maskiner)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner m. trykføler + AEMP)"],
  DeviceTypeEnum["FMB140 LV-CAN (maskiner m. trykføler)"],
  DeviceTypeEnum["FMB204 (maskiner m. trykføler)"],
  DeviceTypeEnum["FMC150 (maskiner)"],
  DeviceTypeEnum["FMC234 (maskiner m. trykføler)"],
  DeviceTypeEnum.FMC234,
  DeviceTypeEnum.FMC150,
  DeviceTypeEnum["FMC150 ext"],
  DeviceTypeEnum.FMC250,
  DeviceTypeEnum["FMC250 ext"],
  DeviceTypeEnum["FMB140 ALL-CAN"],
  DeviceTypeEnum["FMB240 ALL-CAN"],
]

export const gpsTrackerWithBattery = [
  DeviceTypeEnum.TAT100,
  DeviceTypeEnum.TAT140,
]

export const assetTracker = [
  DeviceTypeEnum.Probe,
  DeviceTypeEnum["Probe Mini"],
  DeviceTypeEnum["Probe Plus"],
  DeviceTypeEnum["Probe Ultra"],
]
