/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import { Device } from "./types"

export enum DevicesTypes {
  SetDevices = "Devices/SetDevices",
  UpdateDeviceByID = "Devices/UpdateDeviceByID",
  SetConfigurationFiles = "Devices/SetConfigFiles",
}

type Actions = {
  [DevicesTypes.SetDevices]: {
    devices: Device[]
  }
  [DevicesTypes.UpdateDeviceByID]: {
    device: Device
  }
  [DevicesTypes.SetConfigurationFiles]: {
    files: any
  }
}

export type DevicesActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const DevicesActions = createActions<Actions>()
