import { useContext, useEffect, useMemo } from "react"
import { v4 as uuid } from "uuid"
import { ConfigurationContext } from "./context"
import { useFirestoreDeviceConfigurations } from "services/firestore/deviceconfiguration"
import { DeviceConfiguration } from "./types"
import cogoToast from "@clevertrackdk/cogo-toast"
import { ConfigurationActions, ConfigurationTypes } from "./actions"
import {
  getDisplayKeys,
  patchDeviceCANSettings,
  setDeviceDisplayKeys,
  setVehicleTag,
} from "services/devices"
import { Device } from "app/Devices/types"
import { useFirestoreDevices } from "services/firestore/devices"
import { useFirebaseFunctions } from "services/firebase-functions/functions"

export const useDeviceConfiguration = () => {
  const {
    state: { configurationList },
    dispatch,
  } = useContext(ConfigurationContext)
  const {
    createConfiguration,
    saveConfiguration,
    deleteConfiguration,
    getConfigurations,
  } = useFirestoreDeviceConfigurations()
  const { updateFotaDeviceConfig } = useFirebaseFunctions()
  const { saveFirebaseDevice } = useFirestoreDevices()

  const createNewConfig = async () => {
    const newConfig: DeviceConfiguration = {
      id: uuid(),
      name: "Ny konfiguration",
      displayKeys: [],
      configurationFile: null,
      tag: "",
      features: {},
    }
    try {
      const result = await createConfiguration(newConfig, newConfig.id)
      if (result?.status === "OK" && result.config) {
        dispatch(
          ConfigurationActions(ConfigurationTypes.SetConfiguration, {
            configuration: result.config,
          })
        )
        dispatch(
          ConfigurationActions(ConfigurationTypes.AddConfiguration, {
            configuration: result.config,
          })
        )
      } else {
        cogoToast.error("Kunne ikke oprette ny konfiguration")
      }
      return newConfig
    } catch (error) {
      cogoToast.error("Kunne ikke oprette ny konfiguration")
    }
  }

  const duplicateConfig = async ({
    id,
    name,
    ...config
  }: DeviceConfiguration) => {
    const newConfig: DeviceConfiguration = {
      ...config,
      id: uuid(),
      name: `Kopi af ${name}`,
    }
    try {
      const result = await createConfiguration(newConfig, newConfig.id)
      if (result?.status === "OK" && result.config) {
        dispatch(
          ConfigurationActions(ConfigurationTypes.SetConfiguration, {
            configuration: result.config,
          })
        )
        dispatch(
          ConfigurationActions(ConfigurationTypes.AddConfiguration, {
            configuration: result.config,
          })
        )
      } else {
        cogoToast.error("Kunne ikke oprette ny konfiguration")
      }
      return newConfig
    } catch (error) {
      cogoToast.error("Kunne ikke oprette ny konfiguration")
    }
  }

  const saveConfig = async (config) => {
    try {
      if (config) {
        const result = await saveConfiguration(config, config.id)
        if (result?.status === "OK" && result.config) {
          dispatch(
            ConfigurationActions(ConfigurationTypes.UpdateConfigurationByID, {
              configuration: result.config,
            })
          )
          cogoToast.success("Konfiguration gemt")
        }
      } else {
        cogoToast.warn("Kunne ikke gemme, ingen konfiguration valgt")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke gemme konfigurationen")
    }
  }

  const getConfigs = async () => {
    try {
      const result = await getConfigurations()
      if (result?.status === "OK") {
        dispatch(
          ConfigurationActions(ConfigurationTypes.SetConfigurations, {
            configurationList: result.configs as DeviceConfiguration[],
          })
        )
        return "OK"
      }
      return "Error"
    } catch (error) {
      cogoToast.error("Kunne ikke indlæse konfigurationer")
    }
  }

  const deleteConfig = async (id) => {
    try {
      const result = await deleteConfiguration(id)
      if (result?.status === "OK") {
        dispatch(
          ConfigurationActions(ConfigurationTypes.DeleteConfigurationByID, {
            configID: id,
          })
        )
        cogoToast.success("Konfiguration slettet")
      } else {
        cogoToast.error("Kunne ikke slette konfigurationen")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke slette konfiguration")
    }
  }

  const getAvailableDisplayKeys = async () => {
    try {
      const result = await getDisplayKeys()
      if (result?.result === "OK" && result.data) {
        dispatch(
          ConfigurationActions(ConfigurationTypes.SetAvailableDisplayKeys, {
            displayKeys: result.data?.displayKeys,
          })
        )
      } else {
        cogoToast.error("Kunne ikke indlæse display keys")
      }
    } catch (error) {
      cogoToast.error("Kunne ikke indlæse display keys")
    }
  }

  const selectConfig = (config) => {
    dispatch(
      ConfigurationActions(ConfigurationTypes.SetConfiguration, {
        configuration: config,
      })
    )
  }

  const clearConfig = () => {
    dispatch(ConfigurationActions(ConfigurationTypes.ClearConfiguration, null))
  }

  const updateConfigByID = (config) => {
    dispatch(
      ConfigurationActions(ConfigurationTypes.UpdateConfigurationByID, {
        configuration: config,
      })
    )
  }

  const configurationListOptions = useMemo(() => {
    return configurationList.map((config: DeviceConfiguration) => ({
      label: config.name,
      value: config.id,
    }))
  }, [configurationList])

  const updateDeviceConfiguration = async (
    devices: Device[],
    config: DeviceConfiguration,
    updateVehicles: boolean = false
  ) => {
    try {
      const devicesToUpdate = devices.map((x: Device) => +x.imei)
      const deviceIDsToUpdate = devices.map((x: Device) => +x.id)
      const vehiclesToUpdate = devices.map((x: Device) => +x.vehicleID)
      // Always update firebase with selected configuration
      const fbPromises = devicesToUpdate.map((imei) =>
        saveFirebaseDevice(
          {
            config,
          },
          imei.toString()
        )
      )
      // Confige
      const fotaUpdate = updateFotaDeviceConfig({
        imeiList: devicesToUpdate,
        fileID: config?.configurationFile.value,
      })

      const apiUpdates: Promise<any>[] = []

      if (updateVehicles) {
        // Tag update
        const tagUpdates = vehiclesToUpdate.map((vehicleID) =>
          setVehicleTag(vehicleID, config.tag)
        )
        // Display Keys
        const displayKeysUpdate = vehiclesToUpdate.map((vehicleID) =>
          setDeviceDisplayKeys(vehicleID, config.displayKeys)
        )

        apiUpdates.push(...tagUpdates)
        apiUpdates.push(...displayKeysUpdate)
      }

      // CAN Settings
      const CANSettingsUpdate = deviceIDsToUpdate.map((deviceID) =>
        patchDeviceCANSettings(deviceID, config.features)
      )

      apiUpdates.push(...CANSettingsUpdate)

      const [fotaResult, ...fbResults] = await Promise.all([
        fotaUpdate,
        ...fbPromises,
      ])

      const apiResults = await Promise.all(apiUpdates)

      if (
        fotaResult.data.status ===
          "Bulk task create job was created. Tasks will be created shortly." &&
        !fbResults.map((x) => x?.result).includes("Error") &&
        !apiResults.map((x) => x?.result).includes("error")
      ) {
        cogoToast.success("Enheder korrekt konfigureret.")
        return "OK"
      } else {
        cogoToast.error("Kunne ikke opdatere enhedernes konfiguration.")
        return "Error"
      }
    } catch (error) {}
  }

  useEffect(() => {
    const loadConfigs = async () => {
      await getConfigs()
    }
    loadConfigs()
  }, [])

  return {
    createNewConfig,
    duplicateConfig,
    saveConfig,
    getAvailableDisplayKeys,
    getConfigs,
    deleteConfig,
    selectConfig,
    updateConfigByID,
    clearConfig,
    configurationListOptions,
    updateDeviceConfiguration,
  }
}
