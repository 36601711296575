import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

export enum SecondaryIconPositionEnum {
  TopLeft = "Top left",
  TopRight = "Top right",
  BottomLeft = "Bottom left",
  BottomRight = "Bottom right",
}

type IconIllustrationType = {
  primaryIcon: string
  secondaryIcon?: string
  secondaryIconPosition?: SecondaryIconPositionEnum
}

const StyledPrimaryIcon = styled.div`
  position: relative;
  background: url("/img/gradient-circle.svg") no-repeat;
  background-size: auto;
  width: 60vw;
  height: 60vw;
  ${tw`p-4 mx-auto flex items-center`}

  svg {
    ${tw`fill-white w-52 h-52 mx-auto`}
  }
`

const StyledSecondaryIcon = styled.div<{
  position: SecondaryIconPositionEnum
}>`
  position: absolute;
  ${tw`bg-brand-blue-50 rounded-full w-20 h-20 flex items-center shadow-lg`}

  ${(props) => {
    switch (props.position) {
      case SecondaryIconPositionEnum.BottomLeft:
        return `bottom: 2%; left: 4%;`
      case SecondaryIconPositionEnum.BottomRight:
        return `bottom: 2%; right: 4%;`
      case SecondaryIconPositionEnum.TopLeft:
        return `top: 2%; left: 4%;`
      case SecondaryIconPositionEnum.TopRight:
      default:
        return `top: 2%; right: 4%;`
    }
  }}

  svg {
    ${tw`fill-white w-12 h-12 mx-auto`}
  }
`

export const IconIllustration: React.FC<IconIllustrationType> = ({
  primaryIcon,
  secondaryIcon,
  secondaryIconPosition,
  ...props
}) => {
  return (
    <div {...props}>
      <StyledPrimaryIcon>
        <svg>
          <use xlinkHref={`/icons.svg#icon-${primaryIcon}`} />
        </svg>
        {secondaryIcon && secondaryIconPosition && (
          <StyledSecondaryIcon position={secondaryIconPosition}>
            <svg>
              <use xlinkHref={`/icons.svg#icon-${secondaryIcon}`} />
            </svg>
          </StyledSecondaryIcon>
        )}
      </StyledPrimaryIcon>
    </div>
  )
}
