/* Action types */
import { ApiVehicle, ApiVehicleGroup, Device } from "app/Devices/types"
import { ActionMap, createActions } from "../../lib/reducer.types"
import { Account } from "app/Account/types"
import { InstallationScreenEnum, InstallationStep } from "./types"

export enum InstallationTypes {
  SetAccount = "Installation/SetAccount",
  SetAccountDevices = "Installation/SetAccountDevices",
  SetAccountVehicleGroups = "Installation/SetAccountVehicleGroups",
  ClearAccount = "Installation/ClearAccount",
  SetScreen = "Installation/FlowScreen",
  SetDevice = "Installation/SetDevice",
  UpdateDeviceByID = "Installation/UpdateDeviceByID",
  SetCurrentDeviceValues = "Installation/SetCurrentDeviceValues",
  SetInstallationSteps = "Installation/SetInstallationSteps",
  SetInstallationStep = "Installation/SetInstallationStep",
  SetVehicles = "Installation/SetVehicles",
  SetFotaDeviceData = "Installation/SetFotaDeviceData",
  ClearDevice = "Installation/ClearDevice",
}

type Actions = {
  [InstallationTypes.SetAccount]: {
    account: Account
  }
  [InstallationTypes.SetAccountDevices]: {
    accountDevices: Device[]
  }
  [InstallationTypes.ClearAccount]: undefined
  [InstallationTypes.SetScreen]: InstallationScreenEnum | null
  [InstallationTypes.SetDevice]: {
    device: Device
  }
  [InstallationTypes.SetCurrentDeviceValues]: {
    currentDeviceValues: any | null
  }
  [InstallationTypes.UpdateDeviceByID]: {
    device: Device
  }
  [InstallationTypes.SetAccountVehicleGroups]: {
    groups: ApiVehicleGroup[]
  }
  [InstallationTypes.SetInstallationSteps]: {
    steps: InstallationStep[] | null
  }
  [InstallationTypes.SetInstallationStep]: {
    step: InstallationStep
  }
  [InstallationTypes.SetVehicles]: {
    accountVehicles: ApiVehicle[]
  }
  [InstallationTypes.SetFotaDeviceData]: {
    fotaDevice: any
  }
  [InstallationTypes.ClearDevice]: undefined
}

export type InstallationActionMap = ActionMap<Actions>[keyof ActionMap<Actions>]
export const InstallationActions = createActions<Actions>()
