import React, { useState, useEffect, useContext, useMemo } from "react"
import { navigate } from "gatsby"
import cogoToast from "@clevertrackdk/cogo-toast"
import { isLoggedIn, logout } from "services/auth"
import { Dashboard } from "app/Dashboard"
import { AdminUser, BasicUser, UserTypes } from "app/User/types"
import { UserContext } from "app/User/context"
import { UserActions, UserActionTypes } from "app/User/actions"
import { useFirestoreUser } from "services/firestore/user"

interface PrivateRouteProps {
  component: JSX.Element
  feature?: string
  allowAllAuth?: boolean
  path: string
  dashboardTitle?: React.ReactNode | string
  dashboardSidebar?: React.ReactNode
  allowOverflowScroll?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  feature: featureFlag,
  allowAllAuth,
  dashboardTitle,
  dashboardSidebar,
  allowOverflowScroll = false,
  ...rest
}) => {
  const {
    state: { user },
    dispatch,
  } = useContext(UserContext)
  const { getFirebaseUser } = useFirestoreUser()
  // Initially, assume no user or authentication is set.
  const [loading, setLoading] = useState(false)
  const isAuthenticated = useMemo(
    async () => await onAttemptRenderPrivateRoute(),
    []
  )

  async function onAttemptRenderPrivateRoute() {
    try {
      const { authenticated, user } = await isLoggedIn()

      if (authenticated && user) {
        const fbUser = (await getFirebaseUser(user.id.toString())) as Partial<
          UserTypes
        >
        if (fbUser) {
          user.isAccountAdmin = fbUser.isAccountAdmin
          user.userTypes = fbUser.userTypes
        }
        dispatch(
          UserActions(UserActionTypes.SetUser, {
            user,
          })
        )
        setLoading(false)
        return true
      } else {
        dispatch(UserActions(UserActionTypes.ResetUser, null))
        await logout()
        if (typeof window !== `undefined`) navigate("/")
        setLoading(false)
        return false
      }
    } catch (e) {
      cogoToast.error(
        "Kunne ikke forbinde til serveren. Tjek evt. din internetforbindelse."
      )
      return false
    }
  }

  // If authenticated is "false", render null initially. Otherwise render private route.
  return useMemo(
    () =>
      user && user.id ? (
        <Dashboard
          key="dashboard"
          allowOverflowScroll={allowOverflowScroll ?? null}
        >
          <Component {...rest} />
        </Dashboard>
      ) : null,
    [user]
  )
}

export default PrivateRoute
